/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  FontFamily,
  FontSize,
  JSDateFormat,
  Options,
  PrepAndSendRequest,
  RequestData,
  Settings,
  Signature,
  SignatureType,
  User,
  AccountDateFormat,
  File,
  FileType,
  SignerFileType,
  Role,
  Attachment as HelloRequestAttachment,
  DeliveryType,
  Template as HelloRequestTemplate,
  PHPDateFormat,
  CoverPage,
  BulkSend,
  EditorData,
  Rule,
  StringArrayTrigger,
  StringTrigger,
  NonNumericTrigger,
  NumericTrigger,
  StringOperator,
  NonNumericOperator,
  NumericOperator,
  StringArrayOperator,
  Recipient,
  Signer,
  Page,
  Document,
  ApiApp,
  RequestType as RequestTypes,
} from 'js/sign-components/generated/types/HelloRequest';
import { FieldTypes } from 'signer-app/types/editor-types';
import {
  Attachment,
  RecipientTypes,
} from 'hellospa/page/prep-and-send/data/types/recipient';
import {
  CCEmail,
  CCRole,
  CCTypes,
} from 'hellospa/page/prep-and-send/data/types/cc';
import { FilePollResponseStatus } from 'hellospa/page/prep-and-send/data/types/file';
import { DataSchema, SaveDataType } from './prep-and-send';
import { UnreachableError } from 'js/sign-components/common/ts-utils';
import {
  convertIntegrations,
  convertFields,
  getAutoFilledType,
  getValidationType,
} from 'hello-react/web-app-client/namespace/editor-converters';
import * as uuid from 'uuid';
import { identity } from 'lodash';
import type { Trigger } from 'signer-app/conditional-logic';
import {
  Integration,
  IntegrationNames,
} from 'hellospa/page/prep-and-send/data/types/flags';

export function convertForHelloRequest(
  prepAndSendData: SaveDataType,
  user: DataSchema['user'],
  uploadIntegrations: DataSchema['flags']['uploadIntegrations'],
  editorFeatures: DataSchema['editorFeatures'],
): PrepAndSendRequest {
  const { guid, settings, requestType, isMeOnly } = prepAndSendData;
  return {
    guid,
    settings: convertSettingsForHelloRequest(settings),
    options: convertOptionsForHelloRequest(
      settings,
      isMeOnly ?? false,
      editorFeatures,
    ),
    flags: {
      requestType,
    },
    accountSettings: {
      allowTemplateDelete: false,
      allowQualifiedSignature: settings.qes || false,
      smsAuthLeft: 0,
      uploadIntegrations: {
        ...uploadIntegrations,
      },
    },
    requestData: convertToHelloRequestData(prepAndSendData),
    user: convertUserForHelloRequest(user),
  };
}

function convertUserForHelloRequest(user: DataSchema['user']): User {
  return {
    ...user,
    apiIdsEnabled: true,
    isConfirmedAccount: true,
    accountDateFormat: (user.accountDateFormat in AccountDateFormat
      ? user.accountDateFormat
      : AccountDateFormat.MMDDYYYYSeparatedBySlash) as AccountDateFormat,
  };
}

function convertSettingsForHelloRequest(
  settings: DataSchema['data']['settings'],
): Settings {
  return {
    ...settings,
  };
}

function convertOptionsForHelloRequest(
  settings: SaveDataType['settings'],
  isMeOnly: boolean,
  features: DataSchema['editorFeatures'],
): Options {
  return {
    hideContinueButton: false,
    requireSignerInfo: settings.requireSignerInfo,
    isMeOnly,
    features,
  };
}

function convertToHelloRequestData(data: SaveDataType): RequestData {
  const unifiedData = {
    files: data.files.map(convertFileForHelloRequest),
    editorData: convertToEditorData(data),
    recipients: data.recipients.map((recipient, index) =>
      convertRecipient(recipient, index, data.settings.recipientOrder ?? false),
    ),
    ccs: data.ccs.map(convertCCForHelloRequest),
    templates: data.templates.map(convertTemplatesForHelloRequest),
    title: data.document.title,
    message: data.document.message,
    expiresAt: data.document.expiresAt
      ? data.document.expiresAt.toISOString()
      : undefined,
    coverPage: data.coverPage
      ? convertCoverPageForHelloRequest(data.coverPage)
      : undefined,
    bulkSend: convertBulkSendForHelloRequest(data.bulkSend),
  };
  if (unifiedData.editorData.signers.length === 0) {
    unifiedData.editorData.signers = unifiedData.recipients;
  }
  return unifiedData;
}

function convertPage(
  page: SaveDataType['editorFields']['pages'][0],
  files: SaveDataType['files'],
): Page {
  let snapshotGuid =
    page.src.match(
      /\/attachment\/view\?snapshot_guid=(?<snapshot>[\w\d]*)&page=[\d]*/,
    )?.groups?.snapshot ?? '';
  const snapshotGuidFiles = files.filter(
    (file) => file.documentGuid === page.documentId,
  );
  if (snapshotGuidFiles.length > 0 && snapshotGuidFiles[0].guid) {
    // Snapshot Guid from the file is more accurate in some cases
    snapshotGuid = snapshotGuidFiles[0].guid;
  }

  return {
    src: page.src,
    width: page.width,
    height: page.height,
    documentId: page.documentId,
    orientation: page.orientation,
    snapshotGuid,
    cdnSrc: page.cdnSrc,
  };
}

function convertToDocument(file: SaveDataType['files'][0]): Document {
  return {
    id: file.documentGuid ?? '',
    name: file.name,
    editable: file.templateGuid?.length === 0,
    snapshotGuid: file.guid ?? file.rootSnapshotGuid,
  };
}

function convertToEditorData(data: SaveDataType): EditorData {
  const editorData = {
    // Pages are only populated for template requests
    pages: data.editorFields.pages.map((page) => convertPage(page, data.files)),
    fields: data.editorFields.fields.map(convertFields),
    mergeFields: data.editorFields.fields
      .filter((field) => field.signer === 'sender')
      .map(convertFields),
    pdfFields: [],
    rules: data.editorFields.rules.map(convertRulesForHelloRequest),
    transform: {
      rotate: 0,
    },
    documents: data.files.map(convertToDocument),
    signers: data.recipients.map((recipient, index) =>
      convertRecipient(recipient, index, data.settings.recipientOrder ?? false),
    ),
    signerOptions: {
      fieldOptions: {
        dateFormat: JSDateFormat.JSDefault,
      },
    },
    editorOptions: {
      allowEditSigners: true,
      allowEditDocuments: true,
      canUseCustomRegexDataValidation: false,
      canUseDataValidation: false,
    },
  };
  return editorData;
}

function mapOperatorForHelloRequest(
  operator: string,
): StringOperator | NonNumericOperator | NumericOperator | StringArrayOperator {
  switch (operator) {
    case 'is':
      return NonNumericOperator.Is;
    case 'not':
      return NonNumericOperator.Not;
    case 'gt':
      return NumericOperator.Gt;
    case 'lt':
      return NumericOperator.Lt;
    case 'has':
      return StringOperator.Has;
    case 'hasNot':
      return StringOperator.HasNot;
    case 'match':
      return StringOperator.Match;
    case 'any':
      return StringArrayOperator.Any;
    case 'none':
      return StringArrayOperator.None;
    default:
      throw new UnreachableError(operator as never);
  }
}

function convertTriggerForHelloRequest(
  trigger: Trigger,
): StringArrayTrigger | StringTrigger | NonNumericTrigger | NumericTrigger {
  switch (trigger.operator) {
    case 'is':
    case 'not':
      return {
        id: trigger.id,
        operator: mapOperatorForHelloRequest(
          trigger.operator,
        ) as NonNumericOperator,
        value: trigger.value as string | boolean,
      };
    case 'gt':
    case 'lt':
      return {
        id: trigger.id,
        operator: mapOperatorForHelloRequest(
          trigger.operator,
        ) as NumericOperator,
        value: trigger.value as number,
      };
    case 'has':
    case 'hasNot':
    case 'match':
      return {
        id: trigger.id,
        operator: mapOperatorForHelloRequest(
          trigger.operator,
        ) as StringOperator,
        value: trigger.value as string,
      };
    case 'any':
    case 'none':
      return {
        id: trigger.id,
        operator: mapOperatorForHelloRequest(
          trigger.operator,
        ) as StringArrayOperator,
        value: trigger.value as string[],
      };
    default:
      throw new UnreachableError(trigger);
  }
}

function convertRulesForHelloRequest(
  rule: SaveDataType['editorFields']['rules'][0],
): Rule {
  return {
    ...rule,
    triggerOperator: rule.triggerOperator as Rule['triggerOperator'],
    triggers: rule.triggers.map(convertTriggerForHelloRequest),
  };
}

function convertBulkSendForHelloRequest(
  bulkSend: SaveDataType['bulkSend'],
): BulkSend {
  // TODO: Create a SignerFile type when the bulksend flow (DEV-12882) is being worked on
  const { dataKey, ...bulkSendNoDataKey } = bulkSend;
  return {
    ...bulkSendNoDataKey,
    signerFile: bulkSendNoDataKey.signerFile
      ? {
          ...bulkSendNoDataKey.signerFile,
        }
      : null,
  };
}

function convertCoverPageForHelloRequest(
  coverPage: SaveDataType['coverPage'],
): CoverPage {
  return {
    ...coverPage,
    to: coverPage.to ?? '',
    from: coverPage.from ?? '',
    message: coverPage.message ?? '',
  };
}

function convertTemplatesForHelloRequest(
  template: SaveDataType['templates'][0],
): HelloRequestTemplate {
  const { editorFields, document, templateGuid, ...templateCopy } = template;
  return {
    ...templateCopy,
    guid: templateGuid,
    requestType: RequestTypes.SendTemplate,
    recipientReassignment: false,
    recipientOrder: false,
    recipients: template.recipients.map((recipient, index) =>
      convertRecipient(recipient, index, template.recipientOrder),
    ) as Role[],
    message: template.document.message,
    isOwner: true,
    createdBy: {
      name: '',
      email: '',
      date: '',
    },
    dateFormat: template.dateFormat as PHPDateFormat,
    title: template.document.title,
    jsDateFormat: template.jsDateFormat as JSDateFormat,
    files: template.files.map(convertFileForHelloRequest),
    ccs: template.ccs ? template.ccs.map(convertCCForHelloRequest) : undefined,
  };
}

function convertCCForHelloRequest(
  cc: CCEmail | CCRole,
  index: number,
): PrepAndSendRequest['requestData']['ccs'][0] {
  switch (cc.type) {
    case CCTypes.CCEmail:
      return {
        ...cc,
        id: `${index + 1}`,
        role: cc.role
          ? (convertCCForHelloRequest(cc.role, index) as CCRole)
          : undefined,
      };
    case CCTypes.CCRole:
      return {
        ...cc,
      };
    default:
      throw new UnreachableError(cc);
  }
}

function convertRecipient(
  recipient: SaveDataType['recipients'][0],
  index: number,
  recipientOrder: boolean,
): Recipient {
  const id = recipient.id ?? `${index + 1}`;
  switch (recipient.type) {
    case 'fax':
      return {
        ...recipient,
        id,
      };
    case 'signer':
      // eslint-disable-next-line no-case-declarations
      return identity<Signer>({
        type: 'signer',
        name: recipient.name,
        email: recipient.email === '' ? null : recipient.email,
        id,
        role: recipient.role
          ? (convertRecipient(recipient.role, index, recipientOrder) as Role)
          : null,
        attachments: recipient.attachments.map(convertAttachment),
        deliveryType: recipient.deliveryType
          ? (recipient.deliveryType as DeliveryType)
          : DeliveryType.Email,
        smsDeliveryNumber: recipient.smsDeliveryMobileNumber,
        order: recipientOrder ? index : undefined,
        accessCode: recipient.accessCode ?? undefined,
        smsAuthNumber: recipient.smsAuthNumber ?? undefined,
        authType: recipient.authType ?? undefined,
      });
    case 'role':
      return {
        type: 'role',
        name: recipient.name,
        id,
        attachments: recipient.attachments.map(convertAttachment),
        order: recipientOrder ? index : undefined,
      };
    default:
      throw new UnreachableError(recipient as never);
  }
}

function convertAttachment(attachment: Attachment): HelloRequestAttachment {
  return {
    id: attachment.id,
    name: attachment.name,
    instructions: attachment.instructions ?? '',
    required: attachment.required,
  };
}

function convertFileForHelloRequest(
  file: SaveDataType['files'][0],
  index: number,
): File {
  return {
    name: file.name,
    documentGuid: file.documentGuid ?? undefined,
    templateGuid: file.templateGuid ?? undefined,
    type: file.type as FileType,
    guid: file.guid ?? uuid.v4(),
    rootSnapshotGuid: file.rootSnapshotGuid,
    draftSnapshotGuid: file.draftSnapshotGuid,
    fields: [],
    pwRequired: file.pwRequired ?? false,
    externalFileCacheKey: file.externalFileCacheKey ?? undefined,
    externalServiceType: file.externalServiceType ?? undefined,
    order: index,
  };
}

export function convertForPrepAndSend(data: PrepAndSendRequest): DataSchema {
  const { guid, embeddedData, settings, requestData, user, flags, options } =
    data;

  return {
    data: {
      guid,
      bulkSend: convertBulkSend(requestData.bulkSend),
      ccs: requestData.ccs.map(convertCCs),
      coverPage: {
        from: requestData.coverPage?.from,
        message: requestData.coverPage?.message ?? requestData.message,
        to: requestData.coverPage?.to,
      },
      document: {
        message: requestData.message,
        title: requestData.title,
        expiresAt: requestData.expiresAt
          ? new Date(requestData.expiresAt)
          : undefined,
      },
      editorFields: {
        pages: requestData.editorData.pages.map(convertPages),
        rules: requestData.editorData.rules,
        fields: requestData.editorData.fields.map(convertField),
        pdfFields: requestData.editorData.pdfFields.map(convertField),
        initialPageRotation: requestData.editorData.transform?.rotate ?? 0,
      },
      settings: convertSettings(settings),
      files: requestData.files.map(convertFiles),
      recipients: requestData.recipients.map(convertRecipients),
      templates: requestData.templates.map((template) =>
        convertTemplates(template, guid),
      ),
      shouldConvertDocToTemplate: flags.shouldConvertDocToTemplate,
      isMeOnly: options.isMeOnly,
    },
    flags: convertFlags(data),
    editorFeatures: data.options.features,
    user: {
      id: user.id ?? undefined,
      name: user.name ?? '',
      accountDateFormat: user.accountDateFormat,
      email: user.email,
      isPaidAccount: user.isPaidAccount,
      apiIdsEnabled: user.apiIdsEnabled,
      isConfirmedAccount: user.isConfirmedAccount,
      dbxUserId: user.dbxUserId ?? null,
    },
    apiApp: embeddedData?.apiApp
      ? convertApiApp(embeddedData?.apiApp)
      : undefined,
    embeddedData:
      embeddedData != null
        ? {
            docGuids: embeddedData.docGuids,
            editedTemplateGuid: embeddedData.editedTemplateGuid || null,
            isSelfSign: embeddedData.isSelfSign,
            locale: embeddedData.locale,
            parentUrl: embeddedData.parentUrl ?? '',
            redirectUrl: embeddedData.redirectUrl ?? '',
            usingTemplate: embeddedData.usingTemplate,
            apiApp: embeddedData.apiApp,
          }
        : undefined,
  };
}

const convertApiApp = (apiApp: ApiApp): DataSchema['apiApp'] => {
  return {
    clientId: apiApp.clientId,
    parentUrl: apiApp.parentUrl ?? '',
  };
};

const convertSettings = (
  settings: Settings,
): DataSchema['data']['settings'] => {
  const { requireSignerInfo, ...updatedSettings } = settings;
  return updatedSettings;
};

export const convertTemplates = (
  template: PrepAndSendRequest['requestData']['templates'][0],
  requestGuid: string,
): DataSchema['data']['templates'][0] => {
  const { createdBy, editorData, isOwner, title, message, ...templateCopy } =
    template;

  return {
    ...templateCopy,
    guid: requestGuid,
    templateGuid: template.guid,
    jsDateFormat: template.jsDateFormat ?? JSDateFormat.JSDefault,
    editorFields: {
      fields: template.editorData?.fields.map(convertField) ?? [],
      pdfFields: [],
      pages: template.editorData?.pages.map(convertPages) ?? [],
      rules: template.editorData?.rules ?? [],
      initialPageRotation: template.editorData?.transform?.rotate ?? 0,
    },
    files: template.files?.map(convertFiles) ?? [],
    document: {
      title: template.title,
      message: template.message,
      expiresAt: undefined,
    },
    recipients: template.recipients.map(convertRecipients),
    ccs: template.ccs?.map(convertCCs) ?? [],
  };
};

const convertRecipients = (
  recipient: PrepAndSendRequest['requestData']['recipients'][0],
): DataSchema['data']['recipients'][0] => {
  switch (recipient.type) {
    case 'fax':
      return {
        ...recipient,
        type: RecipientTypes.Fax,
      };
    case 'signer': {
      const role = recipient.role
        ? convertRecipients(recipient.role)
        : undefined;
      const { smsDeliveryNumber, ...updatedRecipient } = recipient;

      return {
        ...updatedRecipient,
        email: recipient.email ?? '',
        type: RecipientTypes.Signer,
        deliveryType: recipient.deliveryType ?? 'EMAIL',
        smsDeliveryMobileNumber: recipient.smsDeliveryNumber ?? '',
        role: role?.type === RecipientTypes.Role ? role : undefined,
        accessCode: recipient.accessCode ?? '',
        smsAuthNumber: recipient.smsAuthNumber ?? '',
      };
    }
    case 'role': {
      return {
        ...recipient,
        type: RecipientTypes.Role,
      };
    }

    default:
      throw new UnreachableError(recipient);
  }
};

const convertFiles = (
  file: PrepAndSendRequest['requestData']['files'][0],
): DataSchema['data']['files'][0] => {
  // These fields aren't used in P&S
  const { tsmGroupGuid, status, order, pageCount, ...fileCopy } = file;
  return {
    ...fileCopy,
    fields: file.fields
      .filter((field) => field.signer === 'sender')
      .map(convertField),
  };
};

const convertIntegration = (
  data: PrepAndSendRequest['flags']['integration'],
): DataSchema['flags']['integration'] => {
  let integration: Integration | undefined;
  const defaultData = {
    externalCacheKey: '',
    externalAppKey: '',
    externalAppPickerSrc: '',
    externalAccountId: '',
    externalFolderPath: '',
    externalFilename: '',
    recipientsProvidedByHostPage: false,
    ...data?.data,
  };
  if (data && data.name) {
    switch (data.name) {
      case IntegrationNames.DeepIntegration:
        if (data.data) {
          integration = {
            name: IntegrationNames.DeepIntegration,
            data: defaultData,
          };
        }
        break;
      case IntegrationNames.HubSpot:
        integration = {
          name: IntegrationNames.HubSpot,
          data: defaultData,
        };
        break;
      case IntegrationNames.HubSpotV2:
        integration = {
          name: IntegrationNames.HubSpotV2,
          data: defaultData,
        };
        break;
      case IntegrationNames.OfficeSelfSign:
        integration = {
          name: IntegrationNames.OfficeSelfSign,
          data: defaultData,
        };
        break;
      case IntegrationNames.SharePoint:
        integration = {
          name: IntegrationNames.SharePoint,
          data: defaultData,
        };
        break;
      default:
        integration = undefined;
    }
  }
  return integration;
};

const convertFlags = (data: PrepAndSendRequest): DataSchema['flags'] => {
  const { options, accountSettings, flags } = data;

  return {
    isMobileAppRequest: Boolean(flags.isMobileAppRequest),
    requestType: flags.requestType,

    hideProgressStepper: options.hideProgressStepper === true,
    hideStepperContainer: options.hideStepperContainer === true,

    chargeType: accountSettings.chargeType,
    totalPriceInCents: accountSettings.totalPriceInCents,
    freeFaxPagesLeft: accountSettings.freeFaxPagesLeft,
    faxPageCurrentCount: accountSettings.faxPageCurrentCount,
    overageFaxMaxLimitInCents: accountSettings.overageFaxMaxLimitInCents,
    pricePerFaxInCents: accountSettings.pricePerFaxInCents,
    initialPageLimit: accountSettings.initialPageLimit,
    overagePriceInCentsPerPage: accountSettings.overagePriceInCentsPerPage,
    internationalMultiplier: accountSettings.internationalMultiplier,
    allowTemplateDelete: accountSettings.allowTemplateDelete,
    integration: convertIntegration(flags.integration),
    needsCharge: accountSettings.needsCharge,
    allowCreateEditTemplate: accountSettings.allowCreateEditTemplate === true,
    allowTemplatePreview: accountSettings.allowTemplatePreview === true,
    allowOptionalTemplateSigners:
      accountSettings.allowOptionalTemplateSigners === true,
    uploadIntegrations: convertIntegrations(accountSettings.uploadIntegrations),
    allowSmsSignatureDelivery: accountSettings.allowSmsSignatureDelivery,
    allowRecipientAccessCodes: accountSettings.allowRecipientAccessCodes,
    allowBulkSend: accountSettings.allowBulkSend,
    allowQualifiedSignature: accountSettings.allowQualifiedSignature,
    allowNom151: accountSettings.allowNom151,
    allowRecipientAttachments: accountSettings.allowRecipientAttachments,
    allowIntegrationsUpload: accountSettings.allowIntegrationsUpload,
    allowRecipientReassignment: accountSettings.allowRecipientReassignment,
    allowRecipientSmsAuthNumbers: accountSettings.allowRecipientSmsAuthNumbers,
    allowExpiresAt: flags.allowExpiresAt,
    allowSmsAuth: accountSettings.allowSmsAuth,
    smsAuthLeft: accountSettings.smsAuthLeft,
    alwaysSendMeACopy: accountSettings.alwaysSendMeACopy,
    isAtDocumentLimit: accountSettings.isAtDocumentLimit,
    isAtTemplateLimit: accountSettings.isAtTemplateLimit,
    alwaysSendOthersACopy: accountSettings.alwaysSendOthersACopy,
    canAccessRecipientAccessCodes:
      accountSettings.canAccessRecipientAccessCodes,
    blockCC: options.blockCC,
    displayDocToTemplateBanner: flags.displayDocToTemplateBanner,
    canUpgradeTierSmsSignatureDelivery:
      accountSettings.canUpgradeTierSmsSignatureDelivery,
    shouldConvertDocToTemplate: flags.shouldConvertDocToTemplate,
    templateConversionCandidateId: flags.templateConversionCandidateId,
    lockDocumentMessage: options.lockDocumentMessage,
    lockDocumentTitle: options.lockDocumentTitle,
    forceSignerRoles: options.forceSignerRoles,
    forceSubjectMessage: options.forceSubjectMessage,
    isHelloFax: flags.isHelloFax ? flags.isHelloFax : undefined,
    noMultiFaxes: accountSettings.noMultiFaxes,
    hasHipaa: accountSettings.hasHIPAA,
    hasTemplateReuseWarning: flags.hasTemplateReuseWarning,
    isNewHsPlan: accountSettings.isNewHsPlan,
    isTransactionalPlan: accountSettings.isTransactionalPlan,
    isResend: flags.isResend,
    requireSignerInfo: options.requireSignerInfo,
    maxTemplateNumber: accountSettings.maxTemplateNumber,
    isSmsToolsOn: accountSettings.isSMSToolsOn,
    allowUnlimitedSelfSign: options.features?.includes(
      'allowUnlimitedSelfSign',
    ),
    isFromFax: flags.isFromFax,
    canUseCustomRegexDataValidation: flags.canUseCustomRegexDataValidation,
    canUseDataValidation: flags.canUseDataValidation,
    isEIdEnabled: accountSettings.isEIdEnabled,
    disableExternalSignature: flags.disableExternalSignature,
  };
};

const convertPages = (
  page: PrepAndSendRequest['requestData']['editorData']['pages'][0],
): DataSchema['data']['editorFields']['pages'][0] => {
  const { snapshotGuid, ...pageCopy } = page;
  return {
    ...pageCopy,
    orientation: page.orientation === 0 ? 0 : 1,
    documentId: page.documentId!,
  };
};

const convertCCs = (
  cc: PrepAndSendRequest['requestData']['ccs'][0],
): DataSchema['data']['ccs'][0] => {
  if (cc.type === 'ccRole') {
    return {
      type: CCTypes.CCRole,
      id: cc.id,
      name: cc.name,
    };
  } else if (cc.type === 'ccEmail') {
    const role = cc.role ? convertCCs(cc.role) : null;
    return {
      type: CCTypes.CCEmail,
      email: cc.email,
      id: cc.id,
      role: role && role.type === CCTypes.CCRole ? role : null,
    };
  }

  throw new UnreachableError(cc);
};

const convertField = (
  field: PrepAndSendRequest['requestData']['editorData']['fields'][0],
): DataSchema['data']['editorFields']['fields'][0] => {
  const formattedField = {
    type: field.type,
    pageIndex: field.pageIndex,
    documentId: field.documentId,
    id: field.id,
    name: field.name ?? '',
    x: field.x,
    y: field.y,
    width: field.width,
    height: field.height,
    signer: field.signer,
    required: field.required ?? false,
    readOnly: field.readOnly ?? false,
    // linkId: field.linkId ?? undefined,
    isDisabled: field.isDisabled ? true : undefined,
  };

  switch (field.type) {
    case 'text':
      return {
        ...formattedField,
        type: FieldTypes.Text,
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        value: field.value ?? undefined,
        autoFillType: getAutoFilledType(field.autoFillType),
        validationType: getValidationType(field.validationType),
        validationCustomRegex: field.validationCustomRegex ?? undefined,
        validationCustomRegexFormatLabel:
          field.validationCustomRegexFormatLabel ?? undefined,
        placeholder: field.placeholder ?? undefined,
        integrationMetadata: field.integrationMetadata ?? undefined,
        masked: field.masked ?? false,
        lines: field.lines ?? undefined,
      };

    case 'signature':
      return {
        ...formattedField,
        type: FieldTypes.Signature,
        // color: <Color>(field.color) ?? undefined,
        signature: field.signature
          ? <Signature>{
              type: SignatureType.Signature,
              guid: field.signature.guid,
            }
          : undefined,
      };

    case 'initials':
      return {
        ...formattedField,
        type: FieldTypes.Initials,
        // color: <Color>(field.color) ?? undefined,
        signature: field.signature
          ? <Signature>{
              type: SignatureType.Initials,
              guid: field.signature.guid,
            }
          : undefined,
      };

    case 'date':
      return {
        ...formattedField,
        type: FieldTypes.Date,
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        value: field.value ?? null,
        dateFormat: <JSDateFormat>field.dateFormat ?? JSDateFormat.JSDefault,
      };

    case 'checkbox':
      return {
        ...formattedField,
        type: FieldTypes.Checkbox,
        checked: field.checked ?? undefined,
        requirement: field.requirement ?? undefined,
        groupLabel: field.groupLabel ?? undefined,
        group: field.group ?? undefined,
        integrationMetadata: field.integrationMetadata ?? undefined,
      };

    case 'radiobutton':
      return {
        ...formattedField,
        type: FieldTypes.Radio,
        checked: field.checked ?? false,
        requirement: field.requirement ?? '',
        groupLabel: field.groupLabel ?? undefined,
        group: field.group ?? undefined,
      };

    case 'dropdown':
      return {
        ...formattedField,
        type: FieldTypes.Dropdown,
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        options: field.options ?? [],
      };

    case 'hyperlink':
      return {
        ...formattedField,
        type: FieldTypes.Hyperlink,
        fontSize: <FontSize>field.fontSize ?? 12,
        fontFamily: <FontFamily>field.fontFamily ?? FontFamily.Arial,
        value: field.value ?? null,
        url: field.url ?? '',
      };
    default:
      throw new UnreachableError(field);
  }
};

function convertBulkSend(
  bulkSend: PrepAndSendRequest['requestData']['bulkSend'],
): DataSchema['data']['bulkSend'] {
  const { exampleFields, signerFile } = bulkSend;

  if (signerFile) {
    return {
      exampleFields,
      signerFile: {
        name: signerFile.name,
        status: signerFile.status ?? FilePollResponseStatus.Ok,
        type: SignerFileType.Signer,
      },
      dataKey: '',
    };
  }
  return { exampleFields, signerFile: null, dataKey: '' };
}

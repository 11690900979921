import React from 'react';
import ErrorBoundary from 'signer-app/parts/error-boundary';
import Notification from 'common/components/notifications';
import NotificationList from 'hellospa/components/notification-banner';
import {
  DefaultLayoutProvider,
  LayoutContextShape,
} from 'hellosign/common/layout/context';
import { CondorLayoutProvider } from 'hellospa/common/layouts/condor/contor-layout-context';
import HsStyleProvider from 'hellosign/common/style/hs-style-provider';
import { HSUser, HSUserProvider } from 'hellospa/common/hs-user';
import { CapturePageProps } from 'hellospa/components/capture-page-props';
import HelloReactApplication, {
  HelloReactApplicationProps,
} from 'hello-react/components/hello-react-application';
import { TrackMountedRoutes } from './components/labeled-route';
import { FeatureFlags } from 'js/sign-components/common/feature-flags';
import { GlobalPageProps } from './slices/page-props';
import { SiteCode } from 'js/sign-components/generated/types/WebApp';
import { setSiteCode } from 'js/sign-components/common/use-site-code';

type Props = React.PropsWithChildren<{
  csrfToken: string;
  featureFlags: FeatureFlags;
  preloadedTsmGroupKey: string;
  layoutContext: LayoutContextShape;
  hsUser: HSUser;
  pageProps?: GlobalPageProps;
  siteCode: SiteCode;
}> &
  Pick<
    HelloReactApplicationProps,
    'store' | 'appActions' | 'history' | 'completedTours'
  >;

/**
 * <HelloSPA is rendered in the web app, Storybook, and tests.  It handles all
 * of the common tools/contexts of everything in the `hellospa.js` bundle.
 *
 * This is a thin wrapper around <HelloReactApplication, which handles the
 * common tools/contexts common to all of `hellosign-web`. At the moment
 * `<HelloSPA` is its only consumer.
 *
 * NOTE: This component doesn't know anything about any specific pages. A
 * component that may or may not have a router gets mounted into this tree.
 * That allows us to pass our central `<Routes
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231213399&cot=14
 */
export function HelloSPA(props: Props) {
  setSiteCode(props.siteCode);

  return (
    <HelloReactApplication
      store={props.store}
      appActions={props.appActions}
      csrfToken={props.csrfToken}
      featureFlags={props.featureFlags || {}}
      history={props.history}
      // This is a list of FORCE-DISABLED tours.
      completedTours={[
        ...(props.completedTours || []),
        // TODO: https://hellosign.atlassian.net/browse/DEV-14121
        // Keep the mobile editor tour off until the backend is complete
        'mobile-editor',
      ]}
    >
      <HsStyleProvider>
        <HSUserProvider value={props.hsUser}>
          <Notification />
          <NotificationList /> {/* P&S notification system */}
          <DefaultLayoutProvider value={props.layoutContext}>
            <CondorLayoutProvider value={props.layoutContext}>
              <TrackMountedRoutes>
                <ErrorBoundary>{props.children}</ErrorBoundary>
              </TrackMountedRoutes>
            </CondorLayoutProvider>
          </DefaultLayoutProvider>
          <CapturePageProps pageProps={props.pageProps} />
        </HSUserProvider>
      </HsStyleProvider>
    </HelloReactApplication>
  );
}

import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import classnames from 'classnames';
import * as uuid from 'uuid';
import { Button } from '@dropbox/dig-components/buttons';
import { Banner } from '@dropbox/dig-components/banner';
import { trackHeapCustomEvent } from 'signer-app/utils/heap';
import { useFlashMessages } from 'signer-app/parts/notifications/flash-messages';

import styles from 'signer-app/parts/notifications/notification-banners.module.css';
import {
  NotificationBannerContext,
  NotificationBannerDataShape,
  NotificationID,
  NotificationsShape,
  useNotificationBanners,
} from 'signer-app/parts/notifications/notification-banner-context';

const messages = defineMessages({
  close: {
    id: '',
    description: 'Close button',
    defaultMessage: 'Close',
  },
});

export type NotificationBannerProps = NotificationBannerDataShape & {
  id: NotificationID;
};

export function NotificationBannerProvider({
  children,
}: React.PropsWithChildren<object>) {
  const flashMessages = useFlashMessages();
  const [notifications, setNotifications] = React.useState<NotificationsShape>(
    () => flashMessages.map((message) => [uuid.v4(), message]),
  );

  // Send a new notification.
  const sendNotification = React.useCallback(
    (data: NotificationBannerDataShape) => {
      const notificationId = uuid.v4();

      setNotifications((prevState) => [...prevState, [notificationId, data]]);

      return notificationId;
    },
    [],
  );

  // Clears an existing notification by id.
  const clearNotification = React.useCallback((targetId) => {
    setNotifications((prevState) => {
      return prevState.filter(([id]) => id !== targetId);
    });
  }, []);

  // Clears all notifications.
  const clearNotifications = React.useCallback(() => {
    setNotifications([]);
  }, []);

  return (
    <NotificationBannerContext.Provider
      value={{
        notifications,
        sendNotification,
        clearNotification,
        clearNotifications,
      }}
    >
      {children}
    </NotificationBannerContext.Provider>
  );
}

function NotificationBanner({
  id,
  html,
  message,
  heapId,
  actions,
  onRequestClose,
  animate = true,
  withCloseButton = true,
  type = 'attention',
  autoClose = true,
  delay = 7500,
  withLeftIcon,
}: NotificationBannerProps) {
  const intl = useIntl();
  const { clearNotification } = useNotificationBanners();
  const [isFading, setIsFading] = React.useState(false);
  const fadeTimeout = React.useRef<NodeJS.Timeout>();
  const closeTimeout = React.useRef<NodeJS.Timeout>();

  const close = React.useCallback(() => {
    clearNotification(id);
  }, [clearNotification, id]);

  const onRequestCloseProxy = React.useCallback(() => {
    if (onRequestClose) onRequestClose();

    close();
  }, [close, onRequestClose]);

  const stopFadeTimeout = React.useCallback(() => {
    setIsFading(false);

    if (fadeTimeout.current) {
      clearTimeout(fadeTimeout.current);
    }
  }, []);

  const stopCloseTimeout = React.useCallback(() => {
    stopFadeTimeout();

    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
  }, [stopFadeTimeout]);

  const startFadeTimeout = React.useCallback(() => {
    stopFadeTimeout(); // Ensure fade timeout is stopped.

    fadeTimeout.current = setTimeout(() => {
      setIsFading(true);
    }, delay - 2500);
  }, [stopFadeTimeout, delay]);

  const startCloseTimeout = React.useCallback(() => {
    stopCloseTimeout(); // Ensure close timeout is stopped.

    startFadeTimeout();

    closeTimeout.current = setTimeout(() => {
      close();
    }, delay);
  }, [stopCloseTimeout, startFadeTimeout, close, delay]);

  React.useEffect(() => {
    if (autoClose) {
      startCloseTimeout();
    }

    if (heapId) {
      trackHeapCustomEvent('notice_displayed', { slug: heapId });
    }
  }, [autoClose, startCloseTimeout, heapId]);

  return (
    <div
      className={classnames(styles.notificationBanner, {
        [styles.isAnimated]: animate,
        [styles.isAnimated && styles.isFading]: isFading,
      })}
      // Conditional props only set if autoClose === true.
      {...(autoClose && {
        onMouseEnter: stopCloseTimeout,
        onMouseLeave: startCloseTimeout,
      })}
    >
      <>
        <Banner
          type={type}
          onRequestClose={onRequestCloseProxy}
          data-testid="notification-banner"
          withLeftIcon={withLeftIcon}
          // Conditional props only set if withCloseButton === true.
          {...(withCloseButton && {
            withCloseButton: intl.formatMessage(messages.close),
          })}
        >
          <Banner.Message>
            {html ? (
              <span
                dangerouslySetInnerHTML={{ __html: html /* DPC_REMOVE */ }}
              />
            ) : (
              message
            )}
          </Banner.Message>
          {actions && actions.length > 0 && (
            <Banner.Actions>
              {actions.map((btnProps, i) => (
                <Button key={i} {...btnProps} />
              ))}
            </Banner.Actions>
          )}
        </Banner>
      </>
    </div>
  );
}

export function NotificationBanners({
  className,
}: Pick<React.HTMLProps<HTMLDivElement>, 'className'>) {
  const { notifications } = useNotificationBanners();

  if (!notifications.length) {
    return null;
  }

  return (
    <div className={classnames(styles.notificationBanners, className)}>
      {notifications.map(([id, data]) => (
        <NotificationBanner key={id} id={id} {...data} />
      ))}
    </div>
  );
}

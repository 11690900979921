import * as Yup from 'yup';
import { SignatureActionFlow } from 'pap-events/enums/signature_action_flow';

import { groupBy, notEmpty } from 'js/sign-components/common/ts-utils';
import { includes } from 'hellospa/js-utils';
import { defineMessages } from 'react-intl';
import {
  StoreShape,
  PREP_AND_SEND_NAMESPACE_KEY,
} from 'hellospa/redux/namespaces';
import * as actions from './actions';
import {
  PrepAndSendNamespaceState,
  RequestTypes,
  EditorFields,
  Contact,
} from './types';
import {
  CC,
  meOnlyMultiCCEmailSchema,
  multiCCEmailSchema,
  multiCCRoleSchema,
  CCTypes,
  CCEmail,
  CCRole,
} from './types/cc';
import { dropinSchema } from './types/deep-integration';
import {
  signerFileSchema,
  userFileSchema,
  UserFilesKeyed,
  FilePollResponseStatus,
  FieldsKeyed,
  UserFileKeyed,
  UserFile,
  UserFileTypes,
  multiUserFileSchema,
  SignerFile,
  MergeField,
} from './types/file';
import {
  AttachmentsKeyed,
  Recipient,
  RecipientTypes,
  multiFaxSchema,
  multiSignerRoleSchema,
  multiSignerSchema,
  multiRoleSchema,
  Attachment,
  RecipientKeyed,
} from './types/recipient';
import { Workflow, WorkflowTags } from './types/workflow';
import { AnalyticsRequestType } from './types/request-types';
import { Field } from 'signer-app/types/editor-types';
import { getFormattedPhoneOptions } from 'libphonenumber-wrapper/utils';
import { makeWeakCache } from 'hello-react/utils/make-weak-cache';

const messages = defineMessages({
  templateTitleText: {
    id: '',
    description:
      'prep and send document, label, label for input field, asks users to provide title for a template',
    defaultMessage: 'Template title',
  },
  titleText: {
    id: '',
    description:
      'prep and send document, label, label for input field, asks users to provide general title for request',
    defaultMessage: 'Title',
  },
  documentTitleText: {
    id: '',
    description:
      'prep and send document, label, label for input field, asks users to to provide document title',
    defaultMessage: 'Document title',
  },
});

export const getPrepAndSendState = (
  state: StoreShape,
): PrepAndSendNamespaceState => state[PREP_AND_SEND_NAMESPACE_KEY];

export const getFlags = (state: StoreShape) => getPrepAndSendState(state).flags;

export const getApiApp = (state: StoreShape) =>
  getPrepAndSendState(state).apiApp;

export const getSettings = (state: StoreShape) =>
  getPrepAndSendState(state).settings;

export const getMeta = (state: StoreShape) => getPrepAndSendState(state).meta;

// TODO: clean up when DEV-12786 is done
export const getNextClickTracker = (state: StoreShape) =>
  getPrepAndSendState(state).nextClickTracker;

const workflowCache = new WeakMap<StoreShape, Workflow>();
export const getWorkflow = (state: StoreShape): Workflow => {
  if (!workflowCache.has(state)) {
    const workflow = getPrepAndSendState(state).workflow;

    // A component's getText can call selectors that call `getWorkflow`, so we
    // need to put the workflow into the cache immediately.
    //
    // This was specifically added to fix the "Maximum call stack size exceeded"
    // error from this series of calls.
    // getWorkflow -> PlaseFields.getText -> isStrictlyEmbedded -> isEmbedded -> getWorkflow
    workflowCache.set(state, workflow);

    const pages = workflow.pages.map((page) => {
      return {
        ...page,
        text: page.component.getText(workflow, state),
      };
    });

    workflowCache.set(state, {
      ...workflow,
      pages,
    });
  }
  const workflow = workflowCache.get(state);
  // This is here to make TypeScript happy because the `if` block above will
  // always populate the workflow if it's missing.
  if (!workflow) {
    throw new Error('Missing workflow');
  }
  return workflow;
};

export const getUploadIntegrations = (state: StoreShape) =>
  getFlags(state).uploadIntegrations;

export const getCurrentWorkflowPageIndex = (state: StoreShape): number => {
  const { currentPath } = getMeta(state);
  const currentPageIndex = getWorkflow(state).pages.findIndex(
    (page) => page.path === currentPath,
  );

  return currentPageIndex < 0 ? 0 : currentPageIndex;
};

export const getRequestType = (state: StoreShape) =>
  getWorkflow(state).requestType;

export const isMobileAppRequest = (state: StoreShape) =>
  getFlags(state).isMobileAppRequest;
export const isEmbedded = (state: StoreShape) => {
  const { requestType } = getWorkflow(state);
  return (
    requestType === RequestTypes.EmbeddedRequest ||
    requestType === RequestTypes.EmbeddedTemplate ||
    isMobileAppRequest(state)
  );
};

export const isNewHSPlan = (state: StoreShape) =>
  Boolean(getFlags(state).isNewHsPlan);

export const isTransactionalPlan = (state: StoreShape) =>
  Boolean(getFlags(state).isTransactionalPlan);

export const getIntegration = (state: StoreShape) =>
  getFlags(state).integration;

export const getIntegrationName = (state: StoreShape) => {
  const integration = getIntegration(state);
  return integration ? integration.name : 'standalone';
};

export const isDeepIntegration = (state: StoreShape) => {
  const integration = getIntegration(state);
  // TypeScript checks that these names are valid even though I'm using strings
  // instead of directly referencing the enum.
  return integration && integration.name === 'deepintegration';
};

export const isSharePointIntegration = (state: StoreShape) => {
  const integration = getIntegration(state);
  return integration && integration.name === 'SharePoint';
};

export const isHubSpotIntegration = (state: StoreShape) => {
  const integration = getIntegration(state);
  return integration && integration.name === 'hubspot';
};

export const isHubSpotV2Integration = (state: StoreShape) => {
  const integration = getIntegration(state);
  return integration && integration.name === 'hubspotv2';
};

// deep integration is also an embedded request, but some functionalities don't
// apply, so, this function will return if it's HelloSign specific embedded
export const isStrictlyEmbedded = (state: StoreShape) =>
  isEmbedded(state) && !isDeepIntegration(state);

export const isEmbeddedRequest = (state: StoreShape) => {
  const { requestType } = getWorkflow(state);
  return requestType === RequestTypes.EmbeddedRequest;
};

export const isEmbeddedTemplate = (state: StoreShape) => {
  const { requestType } = getWorkflow(state);
  return requestType === RequestTypes.EmbeddedTemplate;
};

export const getUser = (state: StoreShape) => getPrepAndSendState(state).user;

export const getClientId = (state: StoreShape) => {
  const prepAndSendState = getPrepAndSendState(state);
  return prepAndSendState.apiApp ? prepAndSendState.apiApp.clientId : undefined;
};

export const isPaidAccount = (state: StoreShape) =>
  Boolean(getPrepAndSendState(state).user.isPaidAccount);

export const getAccountDateFormat = (state: StoreShape) =>
  getPrepAndSendState(state).user.accountDateFormat;

export const numDocsLeft = (state: StoreShape) =>
  getPrepAndSendState(state).user.numDocsLeft;
export const getNumFields = (state: StoreShape) => {
  return getPrepAndSendState(state).numFields;
};

const attachmentsToArray = (attachments: AttachmentsKeyed): Attachment[] =>
  Object.values(attachments)
    .filter(notEmpty)
    .map((attachment) => attachment);

const recipientArrayCache = makeWeakCache(
  (recipientsKeyed: RecipientKeyed[]): Recipient[] => {
    return recipientsKeyed.filter(notEmpty).map((recipient) => {
      if ('attachments' in recipient) {
        return {
          ...recipient,
          attachments: attachmentsToArray(recipient.attachments),
        };
      }

      return recipient;
    });
  },
);
const recipientAttachmentsToArray = (state: StoreShape): Recipient[] => {
  const recipientsKeyed = getPrepAndSendState(state).recipients;
  return recipientArrayCache(recipientsKeyed);
};

export const getNextRecipientId = (state: StoreShape) =>
  // The backend and Editor expect IDs to be numeric(-ish?). generateSigner uses
  // a temporary UUID and then before storing the new Recipient the UUID is
  // replaced with a backend-compatible value. I'm casting it to a string
  // because I don't see a need to deal with the type being string | number. I'd
  // really prefer to just use UUIDs end-to-end, but right now I just need this
  // to be compatible with existing code.
  String(getMeta(state).nextRecipientId);

export const getRecipients = (state: StoreShape): Recipient[] =>
  recipientAttachmentsToArray(state);

export const getRecipient = (state: StoreShape, id: Recipient['id']) =>
  getRecipients(state).find((r) => r.id === id);

export const getCC = (state: StoreShape, id: CC['id']) =>
  getPrepAndSendState(state).ccs.find((cc) => cc.id === id);

type RecipientForType<T extends RecipientTypes> = Extract<
  Recipient,
  { type: T }
>;

const recipientsOfType = makeWeakCache(
  <T extends RecipientTypes>(recipients: Recipient[], type: T) => {
    return recipients.filter(
      (recipient): recipient is RecipientForType<T> => recipient.type === type,
    );
  },
);
export const getRecipientsOfType = <T extends RecipientTypes>(
  state: StoreShape,
  type: T | undefined,
) => {
  if (!type) {
    return [];
  }
  return recipientsOfType(getRecipients(state), type);
};

export const getEmbeddedData = (state: StoreShape) =>
  getPrepAndSendState(state).embeddedData;

export const getEmbeddedParentUrl = (state: StoreShape) =>
  getEmbeddedData(state)?.parentUrl ?? null;

export const isEmbeddedSelfSign = (state: StoreShape) => {
  const embeddedData = getEmbeddedData(state);
  return embeddedData ? embeddedData.isSelfSign : false;
};

export const isMeOnly = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (isEmbeddedSelfSign(state)) {
    return true;
  }

  if (!includes(tags, [WorkflowTags.SignatureRequestDocument])) {
    return false;
  }
  return getRecipients(state).length === 0;
};

export const getRecipientsForRequest = (state: StoreShape): Recipient[] => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return getRecipientsOfType(state, RecipientTypes.Fax);
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    return getRecipientsOfType(state, RecipientTypes.Signer);
  }

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
    ])
  ) {
    return getRecipientsOfType(state, RecipientTypes.Role);
  }

  return [];
};

export const allowOptionalTemplateSigners = (state: StoreShape) => {
  return getFlags(state).allowOptionalTemplateSigners;
};

export const allowUnlimitedSelfSign = (state: StoreShape) => {
  return getFlags(state).allowUnlimitedSelfSign;
};

export const allowTemplatePreview = (state: StoreShape) => {
  return getFlags(state).allowTemplatePreview;
};

export const canCreateEditTemplate = (state: StoreShape) => {
  return getFlags(state).allowCreateEditTemplate;
};

export const allowQES = (state: StoreShape) => {
  return Boolean(getFlags(state).allowQualifiedSignature);
};

export const allowNom151 = (state: StoreShape) => {
  return Boolean(getFlags(state).allowNom151);
};

export const isEIdEnabled = (state: StoreShape) => {
  return Boolean(getFlags(state).isEIdEnabled);
};

// The SMS tools add-on has been purchased and this account has permission to use it
export const allowSmsSignatureDelivery = (state: StoreShape) => {
  return getFlags(state).allowSmsSignatureDelivery;
};

// The SMS tools add-on has been purchased AND its setting is turned on via Admin Console
export const isSmsToolsOn = (state: StoreShape) => {
  return allowSmsSignatureDelivery(state) && getFlags(state).isSmsToolsOn;
};

export const canUpgradeSmsSignatureTier = (state: StoreShape) => {
  return getFlags(state).canUpgradeTierSmsSignatureDelivery;
};

// This function is such a confusing mess of yup objects that IDK how to make
// the types work. - Asa
export const getRecipientValidationSchema = (
  state: StoreShape,
): Yup.ObjectSchema<any> | null => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.BulkSend])) {
    return Yup.object({
      signerFile: signerFileSchema,
    });
  }

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return multiFaxSchema;
  }

  if (
    includes(tags, [WorkflowTags.SignatureRequestTemplate]) &&
    allowOptionalTemplateSigners(state)
  ) {
    return multiSignerRoleSchema;
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    return multiSignerSchema;
  }

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
    ])
  ) {
    return multiRoleSchema;
  }

  return null;
};

export const getCCValidationSchema = (
  state: StoreShape,
): Yup.ObjectSchema | null => {
  const tags = getWorkflow(state).tags;

  if (isMeOnly(state)) {
    return meOnlyMultiCCEmailSchema;
  }

  if (includes(tags, [WorkflowTags.SignatureRequestDocument])) {
    return multiCCEmailSchema;
  }

  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return multiCCEmailSchema.shape({
      files: Yup.array().of(userFileSchema),
    });
  }

  if (includes(tags, [WorkflowTags.CreateTemplate])) {
    return multiCCRoleSchema;
  }

  return null;
};

export const getRecipientGenerator = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return actions.generateFaxRecipient;
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    return actions.generateSigner;
  }

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
    ])
  ) {
    return actions.generateRole;
  }

  return undefined;
};

export const getSelfAsRecipient = (state: StoreShape) => {
  const user = getUser(state);
  const tags = getWorkflow(state).tags;
  const recipients = getRecipientsOfType(state, RecipientTypes.Signer);

  // if the current user's email is already part of the recipients, we shouldn't add them
  if (
    recipients.some(
      (r) => r.email.toLowerCase() === String(user.email).toLowerCase(),
    )
  ) {
    return undefined;
  }

  // adding self as a signer is not possible for templates & template links & fax
  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
      WorkflowTags.SignatureRequestFax,
    ])
  ) {
    return undefined;
  }

  return actions.generateSigner({
    name: user.name,
    email: user.email,
  });
};

export const getCCGenerator = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestDocument])) {
    return actions.generateCCEmail;
  }

  if (includes(tags, [WorkflowTags.CreateTemplate])) {
    return actions.generateCCRole;
  }

  return undefined;
};

export const getAttachments = (state: StoreShape, id: Recipient['id']) => {
  const r = getRecipient(state, id);
  if (r != null && r.type === RecipientTypes.Signer) {
    return r.attachments;
  }
  return [];
};

export const getDocument = (state: StoreShape) =>
  getPrepAndSendState(state).document;

export const getDocumentTitleLabel = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
    ])
  ) {
    return messages.templateTitleText;
  }

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return messages.titleText;
  }

  return messages.documentTitleText;
};

export const getAllowExpiresAt = (state: StoreShape) => {
  return Boolean(getFlags(state).allowExpiresAt);
};

export const getFilesKeyed = (state: StoreShape): UserFilesKeyed =>
  getPrepAndSendState(state).files;

export const haveFilesConverted = (state: StoreShape) => {
  const files = getFilesKeyed(state);

  // returns true if all the files have been converted and has 'ok' as the status
  return Object.values(files)
    .filter(notEmpty)
    .every((file) => file.status === FilePollResponseStatus.Ok);
};

export const getTemplates = (state: StoreShape) =>
  getPrepAndSendState(state).templates;
export const hasTemplates = (state: StoreShape) =>
  getTemplates(state)?.length > 0;
export const getTemplateByGuid = (state: StoreShape, templateGuid: string) => {
  const templates = getTemplates(state);

  return templates.find((template) => template.templateGuid === templateGuid);
};

export const getTemplatesGuids = (state: StoreShape) => {
  return getTemplates(state).map((template) => template.templateGuid);
};

// We should not allow you to add two templates with incompatible date formats,
// so grabbing the first template's data format if it exists
export const getTemplateDateFormat = (state: StoreShape) => {
  const templates = getTemplates(state);
  if (templates.length === 0) {
    return getAccountDateFormat(state);
  }

  return templates[0].jsDateFormat
    ? templates[0].jsDateFormat
    : getAccountDateFormat(state);
};

export const hasTemplateWithReassignmentRecipients = (state: StoreShape) => {
  const templates = getTemplates(state);
  const templatesWithReassignment = templates.find(
    (template) => template.recipientReassignment === true,
  );
  return Boolean(
    templatesWithReassignment &&
      templatesWithReassignment.recipientReassignment,
  );
};

export const hasTemplateWithReorderRecipients = (state: StoreShape) => {
  const templates = getTemplates(state);
  const templatesWithReorder = templates.find(
    (template) => template.recipientOrder === true,
  );
  return Boolean(templatesWithReorder && templatesWithReorder.recipientOrder);
};

const fieldsToArray = (fields: FieldsKeyed): Field[] =>
  Object.values(fields)
    .filter(notEmpty)
    .map((field) => field);

const filesToArray = (state: StoreShape) => {
  const files = getPrepAndSendState(state).files;
  return Object.values(files)
    .filter(notEmpty)
    .map((file: UserFileKeyed): UserFile => {
      const fields: UserFile['fields'] = fieldsToArray(file.fields);
      return {
        ...file,
        fields,
      };
    });
};

export const getFiles = (state: StoreShape) => filesToArray(state);

export const getFile = (state: StoreShape, id: UserFile['rootSnapshotGuid']) =>
  getFiles(state).find((f) => f.rootSnapshotGuid === id);

export const getNextFileOrder = (state: StoreShape) => {
  const files = getFiles(state);

  return files.reduce((order, file) => {
    if (file.order >= order) {
      return file.order + 1;
    }
    return order;
  }, 0);
};

export const getFilesByTemplateGuid = (
  state: StoreShape,
  templateGuid?: string,
) => {
  if (templateGuid == null) {
    return [];
  }

  return getFiles(state).filter(
    (file) =>
      file.type === UserFileTypes.Template &&
      file.templateGuid === templateGuid,
  );
};

export const getUserFileValidationSchema = (): Yup.ObjectSchema =>
  multiUserFileSchema;

const isMergeField = (field: Field) => field.signer === 'sender';
export const getAllMergeFields = (state: StoreShape): MergeField[] => {
  const files = getFiles(state);

  const filesByTemplate = groupBy('templateGuid', files);

  return Object.values(filesByTemplate).flatMap((filesInTemplate) => {
    const fileIds = filesInTemplate.map((f) => f.rootSnapshotGuid);

    const allMergeFieldsInTemplate = filesInTemplate.flatMap((file) =>
      file.fields.filter(isMergeField),
    );

    const fieldsByName = groupBy('name', allMergeFieldsInTemplate);

    return Object.values(fieldsByName).map((field) => ({
      field: field[0],
      error: null,
      fileIds,
    }));
  });
};

export const getCCRecipientType = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.CreateTemplate])) {
    return CCTypes.CCRole;
  }

  return CCTypes.CCEmail;
};

export const getCCs = (state: StoreShape) => getPrepAndSendState(state).ccs;

const ccRequestCache = makeWeakCache(
  (ccs: CC[], ccType: CCTypes, meOnly: boolean): CC[] => {
    const ccRecipients = ccs.filter((cc) => cc.type === ccType);
    if (meOnly && ccRecipients.length === 0) {
      ccRecipients.push(actions.generateCCEmail({}));
    }
    return ccRecipients;
  },
);
export const getCCsForRequest = (state: StoreShape) => {
  const ccs = getCCs(state);
  const ccType = getCCRecipientType(state);
  const meOnly = isMeOnly(state);
  return ccRequestCache(ccs, ccType, meOnly);
};

export const getEditorFields = (state: StoreShape): EditorFields => {
  const editorFields = getPrepAndSendState(state).editorFields;
  const templates = getTemplates(state);
  const groupedEditorFields: EditorFields = {
    pages: editorFields.pages,
    fields: editorFields.fields,
    pdfFields: editorFields.pdfFields,
    rules: [],
    initialPageRotation: editorFields.initialPageRotation,
  };

  // Rules aren't copied to new super group yet, need to get the rules on the template
  templates.forEach((template) => {
    groupedEditorFields.rules = groupedEditorFields.rules.concat(
      template.editorFields.rules,
    );
  });

  return groupedEditorFields;
};

export const getEditorFeatures = (state: StoreShape) =>
  getPrepAndSendState(state).editorFeatures;

export const getEditorFieldsIsIncomplete = (state: StoreShape): boolean => {
  const templates = getTemplates(state);
  let hasInvalidTemplateEditorFields = false;
  // Verifying editor fields on templates as well
  templates.forEach((template) => {
    if (
      template.editorFields.fields.length === 0 ||
      template.editorFields.pages.length === 0
    ) {
      hasInvalidTemplateEditorFields = true;
    }
  });
  if (hasInvalidTemplateEditorFields) {
    return true;
  }
  const editorFields = getEditorFields(state);

  return editorFields.fields.length === 0 || editorFields.pages.length === 0;
};

export const hasSignatureFields = (state: StoreShape): boolean => {
  const editorFields = getEditorFields(state);
  const signatureFields = editorFields.fields.findIndex(
    (f) => f.type === 'signature',
  );

  return signatureFields >= 0;
};

export const getTransmissionGroupGuid = (state: StoreShape) =>
  getPrepAndSendState(state).transmissionGroupGuid;

export const getSignatureRequestId = (state: StoreShape) =>
  getPrepAndSendState(state).signatureRequestId;

function formatEntry(entry: string) {
  let formatted = entry.toLowerCase();
  const pattern = /\s|\(|\)|-|\+/g;
  if (formatted.search(/^[a-z]|@/g) < 0) {
    // Strip special characters out of phone numbers
    formatted = formatted.replace(pattern, '');
  }
  return formatted;
}

const MAX_RESULTS = 10;

export const selectReferAFriendUrl = (state: StoreShape) =>
  getPrepAndSendState(state).referAFriendURL;
export const getContacts = (state: StoreShape) =>
  getPrepAndSendState(state).contacts.sortedData;
export const selectContactsLastUpdated = (state: StoreShape) =>
  getPrepAndSendState(state).contacts.lastUpdated;

export const findContactsByName = (
  state: StoreShape,
  search: Contact['name'] | Contact['value'],
  type: Contact['type'][],
) => {
  const entry = formatEntry(search);
  if (!entry) {
    return [];
  }

  const contacts = getPrepAndSendState(state).contacts;

  const matches = [];
  for (let i = 0; i < contacts.sortedData.length; i++) {
    let current = contacts.sortedData[i];

    if (
      type.includes(current.type) &&
      (formatEntry(current.name).includes(entry) ||
        formatEntry(current.value).includes(entry))
    ) {
      if (current.type === 'F') {
        const results = getFormattedPhoneOptions(current.value);
        if (results.length > 0) {
          const { number } = results[0];
          current = {
            ...current,
            value: number,
          };
        }
      }

      matches.push(current);
      if (matches.length >= MAX_RESULTS) {
        break;
      }
    }
  }
  return matches;
};

export const getCoverPage = (state: StoreShape) => {
  if (!getSettings(state).addCoverPage) {
    return {};
  }

  return getPrepAndSendState(state).coverPage;
};

export const getQES = (state: StoreShape) => {
  return Boolean(getSettings(state).qes);
};

export const getNom151 = (state: StoreShape) => {
  return Boolean(getSettings(state).nom151);
};

export const isHelloFax = (state: StoreShape) =>
  Boolean(getFlags(state).isHelloFax);

export const allowSmsAuth = (state: StoreShape) =>
  Boolean(getFlags(state).allowSmsAuth);

export const smsAuthLeft = (state: StoreShape) => getFlags(state).smsAuthLeft;

export const allowBulkSend = (state: StoreShape) =>
  Boolean(getFlags(state).allowBulkSend);

export const needsCharge = (state: StoreShape) =>
  Boolean(getFlags(state).needsCharge);

export const chargeType = (state: StoreShape) =>
  String(getFlags(state).chargeType);

export const totalPriceInCents = (state: StoreShape) =>
  Number(getFlags(state).totalPriceInCents);

export const freeFaxPagesLeft = (state: StoreShape) =>
  Number(getFlags(state).freeFaxPagesLeft);

export const faxPageCurrentCount = (state: StoreShape) =>
  Number(getFlags(state).faxPageCurrentCount);

export const overageFaxMaxLimitInCents = (state: StoreShape) =>
  Number(getFlags(state).overageFaxMaxLimitInCents);

export const pricePerFaxInCents = (state: StoreShape) =>
  Number(getFlags(state).pricePerFaxInCents);

export const initialPageLimit = (state: StoreShape) =>
  Number(getFlags(state).initialPageLimit);

export const overagePriceInCentsPerPage = (state: StoreShape) =>
  Number(getFlags(state).overagePriceInCentsPerPage);

export const internationalMultiplier = (state: StoreShape) =>
  Number(getFlags(state).internationalMultiplier);

export const isResend = (state: StoreShape) =>
  Boolean(getFlags(state).isResend);

export const noMultiFaxes = (state: StoreShape) =>
  Boolean(getFlags(state).noMultiFaxes);

export const isAtDocumentLimit = (state: StoreShape) =>
  Boolean(getFlags(state).isAtDocumentLimit);

export const displayDocToTemplateBanner = (state: StoreShape) =>
  Boolean(
    getFlags(state).displayDocToTemplateBanner && getFiles(state).length === 1,
  );

export const shouldConvertDocToTemplate = (state: StoreShape) =>
  Boolean(
    getFlags(state).shouldConvertDocToTemplate && getFiles(state).length === 1,
  );

export const hasHipaa = (state: StoreShape) =>
  Boolean(getFlags(state).hasHipaa);

export const isSendHighResFaxEnabled = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return Boolean(getSettings(state).sendHighResFax);
  }

  return false;
};

export const isAddCoverPageEnabled = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return Boolean(getSettings(state).addCoverPage);
  }

  return false;
};

export const allowWorkflowChange = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;
  if (
    includes(tags, [
      WorkflowTags.SignatureRequestUnknown,
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.DeepIntegration,
    ])
  ) {
    return true;
  }

  return false;
};
export const showAttachmentIcon = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.DeepIntegration,
      WorkflowTags.DeepIntegrationSelfSign,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    return false;
  }
  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.CreateTemplate,
    ])
  ) {
    // don't show attachment icon for embedded
    return !isStrictlyEmbedded(state);
  }
  return false;
};

export const allowRecipientAdd = (
  state: StoreShape,
  recipients: Recipient[],
) => {
  const tags = getWorkflow(state).tags;
  const maxRecipients = 20;
  if (
    includes(tags, [
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.BulkSend,
    ])
  ) {
    return false;
  }
  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.SignatureRequestFax,
      WorkflowTags.SignatureRequestDocument,
    ]) &&
    recipients.length < maxRecipients
  ) {
    return true;
  }

  return false;
};

export const allowRecipientDelete = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  // recipients shouldn't be deletable when sending a template
  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return false;
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestFax,
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.CreateTemplate,
    ])
  ) {
    return true;
  }

  return false;
};

export const allowMeOnlyAsSigner = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  // embedded shouldn't have this option, but allow it for SharePoint and deep integration
  if (
    isEmbedded(state) &&
    !isDeepIntegration(state) &&
    !isSharePointIntegration(state)
  ) {
    return false;
  }

  // Shows "I'm the only signer" option if it's a signature
  // request that is not sending a template
  return (
    tags.includes(WorkflowTags.SignatureRequestDocument) &&
    !tags.includes(WorkflowTags.SignatureRequestTemplate)
  );
};
export const isSendingTemplate = (state: StoreShape) => {
  return getWorkflow(state).tags.includes(
    WorkflowTags.SignatureRequestTemplate,
  );
};

export const allowRecipientReassignment = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  // cannot alter request reassignment when sending a template
  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return (
      isSendingTemplate(state) &&
      hasTemplateWithReassignmentRecipients(state) &&
      !isDeepIntegration(state)
    );
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.CreateTemplate,
    ])
  ) {
    return Boolean(getFlags(state).allowRecipientReassignment);
  }

  return false;
};

export const allowRecipientOrder = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  // cannot alter re-ordering when sending a template
  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return (
      isSendingTemplate(state) &&
      hasTemplateWithReorderRecipients(state) &&
      !isDeepIntegration(state)
    );
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.CreateTemplate,
    ])
  ) {
    return true;
  }

  return false;
};

export const isRecipientReassignmentEnabled = (state: StoreShape) => {
  // when sending template, settings is not changed to preserve template settings
  if (isSendingTemplate(state)) {
    return allowRecipientReassignment(state);
  }
  return (
    allowRecipientReassignment(state) &&
    Boolean(getSettings(state).recipientReassignment)
  );
};

export const isRecipientOrderEnabled = (state: StoreShape) => {
  // when sending template, settings is not changed to preserve template settings
  if (isSendingTemplate(state)) {
    return allowRecipientOrder(state);
  }
  return (
    allowRecipientOrder(state) && Boolean(getSettings(state).recipientOrder)
  );
};

export const canAccessRecipientAccessCodes = (state: StoreShape) => {
  // Checks that a non-Enterprise+ user can use recipient access codes regardless of setting
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    // don't allow access codes for embedded requests
    return Boolean(getFlags(state).canAccessRecipientAccessCodes);
  }

  return false;
};

export const allowRecipientAccessCode = (state: StoreShape) => {
  // Checks if an Enterprise+ account has access codes enabled for the team
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    // don't allow access codes for embedded requests
    return Boolean(getFlags(state).allowRecipientAccessCodes);
  }

  return false;
};

export const allowRecipientSmsAuth = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;
  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    return Boolean(getFlags(state).allowRecipientSmsAuthNumbers);
  }

  return false;
};

export const allowRecipientAttachment = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [WorkflowTags.SignatureRequestDocument]) &&
    includes(tags, [WorkflowTags.CreateTemplate])
  ) {
    return false;
  }

  // attachments shouldn't be alterable when sending a template
  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return false;
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.CreateTemplate,
    ])
  ) {
    // don't allow attachments for embedded requests
    return (
      Boolean(getFlags(state).allowRecipientAttachments) &&
      !isStrictlyEmbedded(state)
    );
  }

  return false;
};

export const allowSignerRole = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return true;
  }

  return false;
};

export const hasTemplateReuseWarning = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return Boolean(getFlags(state).hasTemplateReuseWarning);
  }

  return false;
};

export const isDeepIntegrationSelfSign = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.DeepIntegrationSelfSign])) {
    return true;
  }

  return false;
};

export const isTemplateRequest = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
    ])
  ) {
    return true;
  }

  return false;
};

export const isTemplateLinkRequest = (state: StoreShape) => {
  return getWorkflow(state).tags.includes(WorkflowTags.CreateTemplateLink);
};

export const getEmbeddedDocGuids = (state: StoreShape) => {
  const embeddedData = getEmbeddedData(state);
  return embeddedData ? embeddedData.docGuids : [];
};

export const isEmbeddedUsingTemplate = (state: StoreShape) => {
  const embeddedData = getEmbeddedData(state);
  return embeddedData ? embeddedData.usingTemplate : false;
};

export const isEmbeddedEditingTemplate = (state: StoreShape) => {
  const embeddedData = getEmbeddedData(state);
  return Boolean(embeddedData?.editedTemplateGuid);
};

export const isDisabledExternalSignature = (state: StoreShape) => {
  const flags = getFlags(state);
  return Boolean(flags?.disableExternalSignature);
};

export const isForceRoles = (state: StoreShape) => {
  const forceRole = getFlags(state).forceSignerRoles;
  const roles = getRecipientsOfType(state, RecipientTypes.Role);
  const recipientsWereUpdated = getMeta(state).embeddedRecipientsWereUpdated;

  if (forceRole || roles.length === 0 || recipientsWereUpdated) {
    return true;
  }

  return roles.find((role) => role.name.length === 0) != null;
};

export const isForceSigner = (state: StoreShape) => {
  const forceRole = getFlags(state).forceSignerRoles;
  const recipients = getRecipientsOfType(state, RecipientTypes.Signer);
  const recipientsWereUpdated = getMeta(state).embeddedRecipientsWereUpdated;

  if (isEmbeddedSelfSign(state)) {
    return false;
  }

  if (forceRole || recipients.length === 0 || recipientsWereUpdated) {
    return true;
  }

  return recipients.find((recipient) => recipient.name.length === 0) != null;
};

export const getCurrentFile = (state: StoreShape) => {
  const files = getFilesKeyed(state);

  let hasValidFiles;

  if (isDeepIntegration(state) && isEmbeddedUsingTemplate(state)) {
    hasValidFiles = Object.values(files)
      .filter(notEmpty)
      .some((file) => file.status === FilePollResponseStatus.Ok);
  } else {
    // DEV-20455 - We need to fix the convert status in the regular DI flow
    hasValidFiles = Object.values(files)
      .filter(notEmpty)
      .some((file) => file.status !== FilePollResponseStatus.Ok);
  }

  if (!hasValidFiles) {
    return null;
  }
  return Object.values(files)
    .filter(notEmpty)
    .find((file) => file.order === 0);
};

export const getSelectedFileNameForSaveBack = (state: StoreShape) => {
  const integration = getIntegration(state);
  const defaultFileName = getCurrentFile(state);

  if (integration && integration!.data!.externalFilename) {
    return integration.data.externalFilename;
  }
  return defaultFileName ? defaultFileName.name : '';
};

export const getFileNameForSaveBackFromTemplate = (state: StoreShape) => {
  const templates = getTemplates(state);
  if (templates && templates.length > 0) {
    return templates[0]!.document!.title ?? '';
  }
  return '';
};

export const isForceSubjectMessage = (state: StoreShape) => {
  const forceSubject = getFlags(state).forceSubjectMessage;
  const getDocument = getPrepAndSendState(state).document;
  const docWasUpdated = getMeta(state).embeddedDocumentWasUpdated;

  const docTitle = getDocument.title;

  let showTitle = true;
  if (docTitle !== undefined && docTitle.length > 0) {
    showTitle = docWasUpdated || false;
  }

  if (forceSubject || showTitle) {
    return true;
  }

  return false;
};

export const maxTemplateNumber = (state: StoreShape) =>
  Number(getFlags(state).maxTemplateNumber);

export const isAtTemplateLimit = (state: StoreShape) =>
  Boolean(getFlags(state).isAtTemplateLimit);

export const allowDocumentMessage = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.CreateTemplate,
      WorkflowTags.SignatureRequestFax,
      WorkflowTags.BulkSend,
    ])
  ) {
    return true;
  }

  return false;
};

export const isDocumentMessageOptional = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestFax])) {
    return false;
  }
  return true;
};

export const isDocumentSectionEnabled = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
      WorkflowTags.BulkSend,
    ])
  ) {
    return true;
  }

  return false;
};

export const isEmailSectionEnabled = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.SignatureRequestEmail])) {
    return true;
  }

  return false;
};

export const isSignerRolesReviewEnabled = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
    ])
  ) {
    return true;
  }

  return false;
};

export const isSignersReviewEnabled = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    return true;
  }

  return false;
};

export const allowUpload = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
      WorkflowTags.SignatureRequest,
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.IntegrationOneTimeDoc,
    ])
  ) {
    return true;
  }

  return false;
};

export const allowIntegrationsUpload = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.CreateTemplate,
      WorkflowTags.CreateTemplateLink,
      WorkflowTags.SignatureRequest,
      WorkflowTags.SignatureRequestTemplate,
    ])
  ) {
    const integrations = getFlags(state).uploadIntegrations;
    return (
      Boolean(getFlags(state).allowIntegrationsUpload) &&
      // integrations are being set as undefined, so checking if at least one is
      // defined to show the "Upload from" text for integrations
      integrations &&
      Object.values(integrations).length > 0
    );
  }

  return false;
};

export const allowTemplate = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (includes(tags, [WorkflowTags.IntegrationOneTimeDoc])) {
    return false;
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestUnknown,
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.BulkSend,
    ])
  ) {
    return true;
  }

  return false;
};

export const isDownloadFileSectionEnabled = (state: StoreShape) =>
  isMeOnly(state);

export const isApiUser = (state: StoreShape) =>
  Boolean(getPrepAndSendState(state).flags.isApiUser);

export const allowMergeFields = (
  state: StoreShape,
  isMergeFieldFeatureFlow: boolean,
) => {
  const tags = getWorkflow(state).tags;

  const hasFileLength = () => {
    return getFiles(state).length > 0;
  };

  // Merge Fields should be available for Deep Integration and HubSpotV2 users using a template
  // in the side-by-side preview flow.
  if (
    (isDeepIntegration(state) ||
      (isMergeFieldFeatureFlow && isHubSpotV2Integration(state))) &&
    isEmbeddedUsingTemplate(state)
  ) {
    return hasFileLength();
  }

  // merge fields shouldn't be available on the review page for embedded requests
  // they are passed with the API request as parameters.
  if (isEmbedded(state)) {
    return false;
  }

  if (includes(tags, [WorkflowTags.SignatureRequestTemplate])) {
    return hasFileLength();
  }

  return false;
};

export const getBlockCC = (state: StoreShape) => {
  // The Me Only flow is only able to CC or download, so blocking CCs doesn't
  // apply there.
  if (isMeOnly(state)) {
    return false;
  }
  return Boolean(getFlags(state).blockCC);
};

export const allowCC = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.CreateTemplate,
      WorkflowTags.BulkSend,
    ])
  ) {
    return !getBlockCC(state);
  }

  return false;
};

export const allowCCRecipientAdd = (
  state: StoreShape,
  recipients: (CCEmail | CCRole)[],
) => {
  const tags = getWorkflow(state).tags;
  const isFreeUserWithoutApi = !isPaidAccount(state) && !isApiUser(state);
  // For me only case, they can add up to 20
  const meOnlyLimit = isFreeUserWithoutApi ? 3 : 20;
  const maxCC = isMeOnly(state) ? meOnlyLimit : 5;

  // if both of these tags exist, users can't add new CCs to the request,
  // basically this is a Send Template with a One off Document flow
  if (
    tags.includes(WorkflowTags.SignatureRequestTemplate) &&
    tags.includes(WorkflowTags.SignatureRequestDocument)
  ) {
    return false;
  }

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestDocument,
      WorkflowTags.CreateTemplate,
    ]) &&
    recipients.length < maxCC
  ) {
    // We can't use getBlockCC here, because blocking CCs is different from not
    // allowing CCs. If CCs aren't allowed, then we don't show the button at
    // all. But if CCs are blocked, we do show the button but it just shows a
    // tooltip explaining that CCs are blocked.
    return true;
  }

  return false;
};

export const allowCCRecipientDelete = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequestTemplate,
      WorkflowTags.BulkSend,
    ])
  ) {
    return false;
  }

  return !getBlockCC(state);
};

export const allowSingleCCRecipientDelete = (state: StoreShape) =>
  !isMeOnly(state);

export const isSignerInfoRequired = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  if (
    includes(tags, [
      WorkflowTags.SignatureRequest,
      WorkflowTags.SignatureRequestFax,
      WorkflowTags.BulkSend,
    ])
  ) {
    if (!getFlags(state).requireSignerInfo) {
      return false;
    }

    // Flag requires signer info and settings haven't been updated
    if (
      getSettings(state).requireSignerInfo ||
      getSettings(state).requireSignerInfo == null
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const getInitialValueOfNameFields = (state: StoreShape) => {
  const name = getUser(state).name;
  const fullName = name ? name.split(' ') : '';
  // if both first and last name already present
  // returns null to hide name fields
  if (fullName[0] && fullName[1]) {
    return null;
    // returns firstname to autopopulate
  } else if (fullName[0]) {
    return { firstname: fullName[0] };
    // returns lastname to autopopulate
  } else if (fullName[1]) {
    return { lastname: fullName[1] };
    // returns empty object, so user inputs both first and last name fields
  } else if (!fullName[0] && !fullName[1]) {
    return {};
  }
  return null;
};

export const hasTemplateOneOffFile = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  return (
    tags.includes(WorkflowTags.SignatureRequestTemplate) &&
    tags.includes(WorkflowTags.SignatureRequestDocument)
  );
};

export const getDropinSchema = (): Yup.ObjectSchema => dropinSchema;

export const isBulkSend = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;
  return includes(tags, [WorkflowTags.BulkSend]);
};

export const getBulkSendExampleData = (state: StoreShape) => {
  const data = getPrepAndSendState(state).bulkSendData;
  return `data:text/plain;charset=utf-8,${encodeURIComponent(
    data.exampleFields.map((name) => `"${name}"`).join(','),
  )}`;
};

export const getBulkSendData = (state: StoreShape) =>
  getPrepAndSendState(state).bulkSendData;

export const getBulkSendSignerFile = (state: StoreShape) =>
  getBulkSendData(state).signerFile;

export const getRequestIsFinished = (state: StoreShape) => {
  return getPrepAndSendState(state).requestIsFinished;
};

export const getIntegrationError = (state: StoreShape) => {
  return getPrepAndSendState(state).integrationError;
};

export const getDeepIntegrationError = (state: StoreShape) => {
  return getPrepAndSendState(state).deepIntegrationError;
};

export const getBulkSendDataKey = (state: StoreShape) =>
  getBulkSendData(state).dataKey;

export const isStepperHidden = (state: StoreShape) =>
  getWorkflow(state).tags.includes(WorkflowTags.HideStepperToolbar);

export const getExternalFolderPath = (state: StoreShape) => {
  const integration = getIntegration(state);
  if (integration && integration.data) {
    return integration.data.externalFolderPath;
  }
  return '';
};

export const getExternalFilename = (state: StoreShape) => {
  const integration = getIntegration(state);
  if (integration && integration.data) {
    return integration.data.externalFilename;
  }
  return '';
};

export const getExternalDefaultSavePath = (state: StoreShape) => {
  const integration = getIntegration(state);
  if (integration && integration.data) {
    return integration.data.externalDefaultSavePath;
  }
  return '';
};

export const getDbxPickerSrc = (state: StoreShape) => {
  const integration = getIntegration(state);
  if (integration && integration.data) {
    return integration.data.externalAppPickerSrc;
  }
  return '';
};

export const allowFileDeletion = (
  state: StoreShape,
  file: UserFile | SignerFile,
) => {
  if (file.type === UserFileTypes.Template) {
    return getFlags(state).allowTemplateDelete;
  }
  return true;
};

export const showInlinePreview = (state: StoreShape) => {
  const tags = getWorkflow(state).tags;

  return tags.includes(WorkflowTags.ShowInlinePreview);
};

export const getPAPSignatureActionFlow = (
  state: StoreShape,
): SignatureActionFlow | undefined => {
  const requestType = getRequestType(state);

  if (isDeepIntegrationSelfSign(state) || isEmbeddedSelfSign(state)) {
    return 'self_sign';
  }

  return AnalyticsRequestType[requestType];
};

export const getPAPActionSurface = (state: StoreShape): string | undefined => {
  if (isStrictlyEmbedded(state)) {
    return 'sign_embedded';
  } else if (isDeepIntegration(state)) {
    return 'sign_deep_integration';
  } else {
    return 'sign_standalone';
  }
};

export const getCommonPAPData = (state: StoreShape) => {
  const signatureActionFlow = getPAPSignatureActionFlow(state);
  const integrationName = getIntegrationName(state);
  const preloadedTsmGroupKey = getTransmissionGroupGuid(state);
  const actionSurface = getPAPActionSurface(state);
  return {
    integrationName,
    preloadedTsmGroupKey,
    signatureActionFlow,
    actionSurface,
  };
};

import React from 'react';
// Importing all actions here seems to create a dependency loop that breaks tests.
import * as bannerActions from 'hellospa/components/notification-banner/data/actions';
import {
  ErrorMessage,
  ErrorMessageProps,
  FieldHookConfig,
  FormikErrors,
  useField,
  useFormikContext,
} from 'formik';
import { useDispatch } from 'react-redux';
import { Dispatch, ThunkAction } from 'hellospa/redux/types';
import { NotificationBannerType } from '../notification-banner/data/types';
import { Text } from '@dropbox/dig-components/typography';

export type StepperState = {
  isValid: boolean;
  errorMessage?: string;
  isBackDisabled?: boolean;
};
function setStepperState(_state: StepperState) {}
type StepperContextShape = StepperState & {
  setStepperState: typeof setStepperState;
};
export const stepperContext = React.createContext<StepperContextShape>({
  isBackDisabled: false,
  isValid: true,
  setStepperState,
});

export function StepperContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [stepperState, setStepperState] = React.useState<StepperState>({
    isBackDisabled: false,
    isValid: true,
  });

  const value = React.useMemo(
    () => ({ ...stepperState, setStepperState }),
    [stepperState],
  );

  return (
    <stepperContext.Provider value={value}>{children}</stepperContext.Provider>
  );
}
type ConnectFormikProps = {
  transformState?: (state: StepperState) => StepperState;
};
const defaultTransform = (state: StepperState) => state;
export function ConnectFormikToStepper({
  transformState = defaultTransform,
}: ConnectFormikProps) {
  const formik = useFormikContext();
  /**
   * formik is a new object on EVERY RENDER, so it can't be a dependency of
   * useEffect :-(
   */
  const { values, validateForm, validateOnChange } = formik;
  const { setStepperState } = React.useContext(stepperContext);

  React.useEffect(() => {
    async function runValidation() {
      const errors = await validateForm();
      const nextState = transformState({
        isBackDisabled: false,
        isValid: Object.keys(errors).length === 0,
      });
      setStepperState(nextState);
    }

    // This if is only here to make sure values is a dependency of the useEffect
    if (values) {
      runValidation();
    }
  }, [setStepperState, transformState, validateForm, validateOnChange, values]);

  React.useEffect(() => {
    // Reset when unmounting
    return () => {
      setStepperState({ isBackDisabled: false, isValid: true });
    };
  }, [setStepperState]);

  return null;
}

type AutoSaveProps<T> = {
  saveToRedux: (values: T) => any;
  // I can't reference actions directly because it creates a dependency loop.
  reduxSaveAction: () => ThunkAction<Promise<void>>;
  values: T;
  errors: FormikErrors<T>;
};
export function AutoSave<T>({
  saveToRedux,
  values,
  errors,
  reduxSaveAction,
}: AutoSaveProps<T>) {
  const timeout = NODE_ENV === 'test' ? 0 : 500;
  // This useState and useEffect act like a debounce
  const [validValues, setValidValues] = React.useState<null | T>(null);
  const [autoSaveDebouncer, setDebouncer] = React.useState(-1);
  const dispatch = useDispatch<Dispatch>();

  /**
   * debounce(500ms) write values from formikd to Redux
   */
  React.useEffect(() => {
    if (Object.keys(errors).length === 0) {
      const id = setTimeout(() => {
        setValidValues(values);
        // It doesn't matter what the value is, it just needs to change
        setDebouncer((count) => (count + 1) % 100);
      }, timeout);

      // Clear the timeout if the values/errors changed before it triggered.
      return () => clearTimeout(id);
    }
    return () => {};
  }, [errors, timeout, values]);

  React.useEffect(() => {
    if (validValues) {
      // save might be a useCallback that could change. This prevents calling
      // save with previous values if `save` changes.
      saveToRedux(validValues);
      setValidValues(null);
    }
  }, [saveToRedux, validValues]);

  /**
   * debounce(5s) save data to the backend.
   */
  React.useEffect(() => {
    // Only start saving after auto-save triggers for the first time.
    if (autoSaveDebouncer !== -1) {
      const id = setTimeout(() => {
        dispatch(reduxSaveAction());
      }, 5000);

      return () => clearTimeout(id);
    }
    return () => {};
  }, [autoSaveDebouncer, dispatch, reduxSaveAction]);

  return null;
}

/**
 * Why BANNER?
 *
 * Yup can attach an error to the array (this.path) OR it can attach errors to
 * items in the array. My hack to support both is to add a fake path here so
 * that I can return all of the errors. But the code in BannerErrorMessage
 * needs to know that key, so I made a constant to keep them in sync.
 * Making it an ugly value to encourage using the constant.
 */
export const BANNER = 'banner-f36c0ad3d642e52c9';
type BannerErrorMessageProps = { name: string; requireTouched?: boolean };
export function BannerErrorMessage({
  name,
  requireTouched = true,
}: BannerErrorMessageProps) {
  type Banner = { id: string; error: string };
  const [currentBanner, setCurrentBanner] = React.useState<null | Banner>(null);
  const { createTranslatedBannerMessage, removeBannerMessage } =
    bannerActions.useLocalBanner();

  const [, meta] = useField(name);
  // The types for meta.error are wrong. FieldMetaProps<any>['error'] claims to
  // be a string | undefined, but that's only true if you're on a leaf node. If
  // you're watching an array or object, error can be an object.
  const errors = meta.error as undefined | { [BANNER]?: string };

  // Use the touched property from the whole array, but only if
  // field is required to have been touched (true by default)
  const touched = requireTouched ? meta.touched : true;
  // Only use the BANNER error.
  const error = errors ? errors[BANNER] : undefined;

  React.useEffect(() => {
    const lastMessage = currentBanner != null ? currentBanner.error : undefined;

    if (touched && error !== lastMessage) {
      if (currentBanner != null) {
        removeBannerMessage(currentBanner.id);
        setCurrentBanner(null);
      }

      if (typeof error === 'string') {
        const id = createTranslatedBannerMessage(
          error,
          NotificationBannerType.Err,
        );
        return setCurrentBanner({ id, error });
      }
    }
  }, [
    createTranslatedBannerMessage,
    error,
    removeBannerMessage,
    currentBanner,
    touched,
  ]);

  return null;
}

/**
 * This component replaces Formik's native `ErrorMessage` with one that uses
 * DIG's `<Text color="error"`
 */
export const FormikDIGError: React.FC<ErrorMessageProps> = ({ name }) => {
  return (
    <ErrorMessage
      name={name}
      render={(message) => <Text color="error">{message}</Text>}
    />
  );
};

export function useFormikFieldValue<T>(
  propsOrFieldName: string | FieldHookConfig<T>,
): T {
  const [{ value }] = useField<T>(propsOrFieldName);
  return value;
}

/**
 * Formik tracks errors on individual fields. If you don't have a
 * `<FormikDIGError` on the right field, we end up with a validation error we
 * can't see. I've had enough times where I needed to temporarily see all errors
 * to fix something, that I made this component.
 */
export function LogAllFormikErrorsInDev() {
  const formik = useFormikContext();

  React.useEffect(() => {
    if (NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('FormikErrors', formik.errors);
    }
  }, [formik.errors]);

  return null;
}

export function SyncFormikValue({
  name,
  value,
}: {
  name: string;
  value: unknown;
}) {
  const [fieldProps, , helpers] = useField(name);

  React.useEffect(() => {
    if (fieldProps.value !== value) {
      helpers.setValue(value);
    }
  }, [helpers, fieldProps.value, value]);

  return null;
}

import { StoreShape } from './namespaces';
import { ThunkDispatch, ThunkAction as TThunkAction } from '@reduxjs/toolkit';
import { Action, AnyAction, Store as ReduxStore } from 'redux';
import { AppActions } from 'hello-react/web-app-client';
import { FeatureFlags } from 'js/sign-components/common/feature-flags';

// https://phryneas.de/redux-typescript-no-discriminating-union
type AllActions = AnyAction;

export { StoreShape };

// https://code.pp.dropbox.com/view/server/metaserver/static/js/modules/clean/redux/flux_standard_action.ts
export type FSA = {
  // This is the value that your reducer will switch off of.
  // It is the only value required by redux.
  type: string;
  // Payload traditionally encompases any data the user actually cares about.
  // It often comes from the response of an api request.
  payload?: any;
  // Meta is information about the request in progress or debugging data.
  // It's a good place to store information about an inflight request or the parameters
  // passed to the action dispatcher.
  meta?: any;
  // This error is very useful for debugging. Even if you don't plan to
  // query data from the error it is a good idea to include it in the action so it can
  // be inspected with redux devtools.
  error?: any;
};

export type ExtraArgument = () => {
  appActions: AppActions;
  featureFlags: FeatureFlags;
  hasFeatureFlag(name: keyof FeatureFlags): boolean;
};

export type Dispatch<
  A extends AnyAction = AllActions,
  S = StoreShape,
> = ThunkDispatch<S, ExtraArgument, A>;

export type ThunkAction<R> = TThunkAction<
  R,
  StoreShape,
  ExtraArgument,
  AllActions
>;

export type AsyncThunkConfig = {
  state: StoreShape;
  dispatch: Dispatch;
  extra: ExtraArgument;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export interface Store<S = StoreShape, A extends Action = AllActions>
  extends ReduxStore<S, A> {
  dispatch: Dispatch<A, S>;
}

// https://code.pp.dropbox.com/view/server/metaserver/static/js/modules/clean/redux/types.ts
// HelloSign changes:
// 1. I removed Request for now as we are using fetch.
// 2. We can't use a const enum because it isn't supported by Babel
export enum ApiClientStatus {
  // Fetch means "this request is in progress"
  // We called it fetch for a few reasons.
  // 1. window.fetch is the native function for making an ajax request with promises.
  // 2. We wanted something agnostic of HTTP method.
  // 3. We wanted to "make fetch happen."
  Fetch = 'Fetch',

  // Fetch should be deprecated and removed over time. Use Request instead.
  // TODO (mattinsler): refactor uses of Fetch to Request.
  // Request = 'Request',

  // Success means the request has completed successfully.
  // We'll generally save the payload in state along with this status.
  Success = 'Success',
  // Error means the request completed with errors.
  // We'll generally save the error itself in state along with this api status.
  Error = 'Error',
}

import React from 'react';
import NotificationHandler from 'signer-app/parts/notifications/notification-handler';
import { NotificationBannerDataShape } from 'signer-app/parts/notifications/notification-banner-context';
import { useIsLastMountedInstanceOf } from 'signer-app/utils/use-is-last-mounted-instance-of';

const notificationHandler = new NotificationHandler();

type FlashMessageData = {
  header?: string;
  text?: string;
  slug?: string;
  class_name?: string; // eslint-disable-line camelcase
  track_as_heap_event?: boolean; // eslint-disable-line camelcase
  very_dangerous_raw_html?: boolean; // eslint-disable-line camelcase
  auto_close?: boolean; // eslint-disable-line camelcase
};

function getBannerTypeFromClassName(className?: string) {
  switch (className) {
    case 'success':
      return 'success';
    case 'warning':
      return 'warning';
    case 'error':
      return 'alert';
    default:
      return 'attention';
  }
}

export type FlashMessageShape = {
  data: FlashMessageData;
};

export function useFlashMessages(notifications?: FlashMessageShape[]) {
  const notificationsData = notifications ?? notificationHandler.notifications;
  const activeList = useIsLastMountedInstanceOf(useFlashMessages);

  return React.useMemo(() => {
    const notifications: Array<{ data: FlashMessageData }> = [
      ...notificationsData,
    ];

    if (!activeList) {
      return [];
    }

    return notifications.map(({ data }) => {
      let msg = data.header ?? '';

      // Is there also text?
      if (data.text) {
        msg = msg.trim();

        // Add a period to the end of the first message.
        if (msg.slice(-1) !== '.') {
          msg += '.';
        }

        // Add a space to the end of the first message.
        if (msg.slice(-1) !== ' ') {
          msg += ' ';
        }

        msg += data.text;
      }

      const notificationData: NotificationBannerDataShape = {
        heapId: data.slug,
        type: getBannerTypeFromClassName(data.class_name),
        autoClose: data.auto_close ?? true,
      };

      if (data.very_dangerous_raw_html) {
        notificationData.html = msg;
      } else {
        notificationData.message = msg;
      }

      return notificationData;
    });
  }, [notificationsData, activeList]);
}

import {
  getEndpointResource,
  postEndpointResource,
  postJSONEndpointResource,
} from 'hello-react/web-app-client/namespace/admin/endpoints';
import { queryParams } from 'js/sign-components/common/fetch';

/**
 * @typedef {Object} TeamsAPI~Entity
 * @property {string} guid
 * @property {string} name
 */

/**
 * @typedef {TeamsAPI~Entity} TeamsAPI~User
 * @property {string} emailAddress
 */

/**
 * @typedef {TeamsAPI~Entity} TeamsAPI~Team
 * @property {TeamsAPI~User[]} admins
 * @property {string} emailAddress
 * @property {string} lastActive
 * @property {TeamsAPI~User[]} managers
 * @property {number} numMembers
 * @property {string} parentGuid
 */

/**
 * @typedef {EndpointsAPI~Response} TeamsAPI~ParentTeamsResponse
 * @property {TeamsAPI~Entity[]} data.teams
 */

/**
 * @typedef {EndpointsAPI~PagedResponse} TeamsAPI~TeamsResponse
 * @property {TeamsAPI~Team[]} data.teams
 */

/**
 * @typedef {TeamsAPI~TeamsResponse} TeamsAPI~PagedTeamsResponse
 * @property {number} data.numTeams
 */

/**
 * @param {string} teamName
 * @param {string} parentGuid
 * @param {string} csrfToken
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function createTeam(teamName, parentGuid, csrfToken) {
  return postJSONEndpointResource('/org/createTeam', {
    body: JSON.stringify({
      csrfToken,
      teamName,
      parentGuid,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function deleteTeam(guid, csrfToken) {
  return postJSONEndpointResource('/org/deleteTeam', {
    body: JSON.stringify({
      csrfToken,
      guid,
    }),
  });
}

/**
 * @param {string} guid
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.name]
 * @param {string} [opts.parentGuid]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function editTeam(guid, csrfToken, opts) {
  return postJSONEndpointResource('/org/editTeam', {
    body: JSON.stringify({
      csrfToken,
      guid,
      ...opts,
    }),
  });
}

/**
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.metric_type]
 */
export function getGraphReport(csrfToken, opts) {
  const qs = queryParams(opts);

  return getEndpointResource(`/org/metric${qs}`);
}

/**
 * @returns {Promise.<TeamsAPI~ParentTeamsResponse>}
 */
export function getParentTeams() {
  return getEndpointResource('/org/getEligibleParents');
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getRootTeam() {
  return getEndpointResource('/org/getTeam');
}

/**
 * @param {string} guid
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeam(guid) {
  const qs = queryParams({ guid });

  return getEndpointResource(`/org/getTeam${qs}`);
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamDocumentsTemplatesSettings() {
  return getEndpointResource('/org/getTeamSettings?mode=documents_templates');
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamCloudSyncSettings() {
  return getEndpointResource('/org/getAccountSettings?mode=team_file_sync');
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamDeleteDocumentsPolicySettings() {
  return getEndpointResource(
    '/org/getTeamSettings?mode=team_delete_documents_policy',
  );
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamGeneralSettings() {
  return getEndpointResource('/org/getTeamSettings?mode=team_general');
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamSecuritySettings() {
  return getEndpointResource('/org/getTeamSettings?mode=team_security');
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamSignatureRequestSettings() {
  return getEndpointResource(
    '/org/getTeamSettings?mode=team_signature_requests',
  );
}

/**
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function getTeamSyncingSharingSettings() {
  return getEndpointResource('/org/getTeamSettings?mode=team_syncing_sharing');
}

/**
 * @param {number} page
 * @param {number} pageSize
 * @param {string} [team]
 * @returns {Promise.<TeamsAPI~PagedTeamsResponse>}
 */
export function listSubteams(page, pageSize, team) {
  const qs = queryParams({
    page,
    page_size: pageSize,

    // This param is optional
    ...(team ? { team } : {}),
  });

  return getEndpointResource(`/org/listTeams${qs}`);
}

/**
 * @param {number} page
 * @param {number} pageSize
 * @param {string} [team]
 * @returns {Promise.<TeamsAPI~PagedTeamsResponse>}
 */
export function listTeams(page, pageSize, team) {
  const qs = queryParams({
    page,
    page_size: pageSize,
    include_root: 1,

    // This param is optional
    ...(team ? { team } : {}),
  });

  return getEndpointResource(`/org/listTeams${qs}`);
}

/**
 * @param {string} csrfToken
 * @param {string} guid
 * @param {Object} [opts]
 * @param {number} [opts.delete] Whether to delete the account entirely.
 * @param {string} [opts.recipientGuid] Whom to transfer templates to within the org, if anyone.
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function removeUser(guid, csrfToken, opts) {
  return postJSONEndpointResource('/org/removeUser', {
    body: JSON.stringify({
      csrfToken,
      guid,
      ...opts,
    }),
  });
}

/**
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {boolean} [opts.compliance]
 * @param {boolean} [opts.usage]
 */
export function runReport(csrfToken, opts) {
  return postJSONEndpointResource('/org/runReport', {
    body: JSON.stringify({
      csrfToken,
      ...opts,
    }),
  });
}

/**
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.company_name]
 * @param {string} [opts.custom_tagline]
 * @param {number} [opts.industry_id]
 * @param {File|string} [opts.custom_logo_file_path]
 * @returns {Promise.<EndpointsAPI~Response>}
 */
export function updateTeamGeneralSettings(csrfToken, opts) {
  const body = new FormData();
  body.append('csrfToken', csrfToken);

  Object.entries(opts).forEach(([key, value]) => {
    if (value.value || value.value === '') {
      body.append(`${key}[value]`, value.value);
    }

    if (value.lock) {
      body.append(`${key}[lock]`, value.lock);
    }
  });

  return postEndpointResource('/org/updateTeamSettings', {
    body,
  });
}

/**
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.ssoEndpoint]
 * @param {string} [opts.idpCert]
 * @param {string} [opts.idpEntity]
 * @param {boolean} [opts.optionAdminLoginPw]
 * @param {boolean} [opts.optionJit]
 * @param {boolean} [opts.optionAllowOverprovision]
 * @param {boolean} [opts.optionForceSsoAuthentication]
 * @param {boolean} [opts.multifactorAuthSms]
 * @param {boolean} [opts.multifactorAuthApp]
 * @param {boolean} [opts.customSigningRedirectEnabled]
 * @param {string} [opts.customSigningRedirectUrl]
 * @param {string} [opts.dateFormat]
 * @param {string} [opts.everyoneHasSignedEmail]
 * @param {boolean} [opts.isSignatureRemindersEnabled]
 * @param {string} [opts.lockedSelfSignMessage]
 * @param {string} [opts.lockedSelfSignTitle]
 * @param {string} [opts.lockedSignatureRequestMessage]
 * @param {string} [opts.lockedSignatureRequestTitle]
 * @param {string} [opts.requestEmailFrom]
 * @param {string} [opts.requestEmailSignature]
 * @param {boolean} [opts.shouldEnableInPersonSigning]
 * @param {boolean} [opts.shouldEnableTamperProof]
 * @param {boolean} [opts.shouldOfferSignerAccessCode]
 * @param {boolean} [opts.shouldOfferSignerSMSAuthentication]
 * @param {boolean} [opts.lockTeamTemplateCreation]
 * @param {boolean} [opts.qualifiedEsignatureEnabled]
 * @param {boolean} [opts.smsSignatureDelivery]
 * @param {string} [opts.tzCode]
 */
export function updateTeamSettings(csrfToken, opts) {
  return postJSONEndpointResource('/org/updateTeamSettings', {
    body: JSON.stringify({
      csrfToken,
      ...opts,
    }),
  });
}

/**
 * @param {string} csrfToken
 * @param {Object} [opts]
 */
export function updateAccountSettings(csrfToken, opts) {
  return postJSONEndpointResource('/org/updateAccountSettings', {
    body: JSON.stringify({
      csrfToken,
      ...opts,
    }),
  });
}

/**
 * @param {string} csrfToken
 */
export function oneTimeDeleteCompletedDocuments(csrfToken) {
  return postJSONEndpointResource('/org/queueTeamRequestsForDeletion', {
    body: JSON.stringify({
      csrfToken,
    }),
  });
}

/**
 * @param {string} csrfToken
 * @param {Object} [opts]
 */
export function updateOngoingDeleteTeamSetting(csrfToken, opts) {
  return postJSONEndpointResource('/org/updateOngoingDeleteTeamSetting', {
    body: JSON.stringify({
      csrfToken,
      ...opts,
    }),
  });
}

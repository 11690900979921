import { FeatureFlags } from 'js/sign-components/common/feature-flags';

export function checkFeatureFlag(
  name: keyof FeatureFlags,
  featureFlags: FeatureFlags,
): boolean {
  return Boolean(featureFlags[name]);
}

export function hasFeatureFlagFactory(featureFlags: FeatureFlags) {
  return function hasFeatureFlag(name: keyof FeatureFlags): boolean {
    return checkFeatureFlag(name, featureFlags);
  };
}

import React from 'react';

import { FeatureFlags } from 'js/sign-components/common/feature-flags';
import { checkFeatureFlag } from 'js/sign-components/common/check-feature-flag';

// Looking for where to register feature flags? They have
// moved to hello-react/common/feature-flags.ts.

const featureFlagContext = React.createContext<FeatureFlags | null>(null);
export const useRawFeatureFlagContext = () =>
  React.useContext(featureFlagContext);

type ProviderProps = {
  featureFlags: FeatureFlags | null;
  children: React.ReactNode;
};

export function FeatureFlagProvider({ featureFlags, children }: ProviderProps) {
  const parentContext = useRawFeatureFlagContext();

  const value = React.useMemo(() => {
    const entries = Object.entries({
      ...parentContext,
      ...featureFlags,
    }).filter(([, value]) => value);
    return Object.fromEntries(entries);
  }, [featureFlags, parentContext]);

  return (
    <featureFlagContext.Provider value={value}>
      {children}
    </featureFlagContext.Provider>
  );
}

/**
 *
 * @param name
 * @param fallback fallback value if we can't load the feature flag context for whatever reason
 * @returns
 */
export function useFeatureFlag(name: keyof FeatureFlags, fallback?: boolean) {
  const context = useRawFeatureFlagContext();
  if (!context) {
    if (fallback == null) {
      throw new Error('this needs to be rendered in a FeatureFlagProvider');
    }
    return fallback;
  }

  return checkFeatureFlag(name, context);
}

type FeatureFlagProps = {
  name: keyof FeatureFlags;
  value?: boolean;
  children: JSX.Element;
  fallback?: boolean;
};
export function FeatureFlag({
  name,
  children,
  value = true,
  fallback,
}: FeatureFlagProps): JSX.Element | null {
  const enabled = useFeatureFlag(name, fallback);

  if (enabled === value) {
    return children;
  }
  return null;
}

import { createSimpleDataContext } from 'signer-app/utils/create-simple-data-context';

import { ButtonProps } from '@dropbox/dig-components/buttons';
import { BannerProps } from '@dropbox/dig-components/banner';

export type NotificationID = string;
export type NotificationBannerDataShape = {
  type?: BannerProps['type']; // default "attention"
  animate?: boolean; // default true
  withCloseButton?: boolean; // default true
  onRequestClose?: () => void;
  heapId?: string; // if defined, displayed event sent to heap
  autoClose?: boolean; // default true
  delay?: number; // default 7500
  message?: React.ReactNode | string;
  html?: string;
  actions?: ButtonProps[];
  withLeftIcon?: BannerProps['withLeftIcon'];
};

export type NotificationsShape = [
  NotificationID,
  NotificationBannerDataShape,
][];
export type NotificationBannerContextShape = {
  notifications: NotificationsShape;
  sendNotification: (data: NotificationBannerDataShape) => NotificationID;
  clearNotification: (id: NotificationID) => void;
  clearNotifications: () => void;
};

export const NotificationBannerContext =
  createSimpleDataContext<NotificationBannerContextShape>(
    'notificationBanners',
  );

export const useNotificationBanners = NotificationBannerContext.useContext;

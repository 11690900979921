import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import SignatureProviderImpl from 'signer-app/signature-modal/signature-modal-context/signature-provider-impl';
import { getCSRFToken, setCsrfToken } from 'js/sign-components/common/hs-fetch';
import { RouteBoundary } from 'signer-app/parts/error-boundary';
import { constructStore } from 'hellospa/redux/store';
import { buildWebAppClient } from 'hello-react/web-app-client';
import { RedirectTo } from 'hellospa/common/utils/redirect';
import { hasFeatureFlagFactory } from 'js/sign-components/common/check-feature-flag';
import { useOnMount } from 'js/sign-components/common/hooks';
import { useUnsafePageProps } from 'hellospa/slices/page-props';
import { useHSUser } from 'hellospa/common/hs-user';
import { HelloSPA } from './hellospa-app';
import { LabeledRoute } from './components/labeled-route';

const TemplatesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-templates" */ 'hellospa/page/templates'
    ),
);
const EditorPage = React.lazy(
  () => import(/* webpackChunkName: "hellospa-editor" */ './page/editor'),
);
const AdminPage = React.lazy(
  () => import(/* webpackChunkName: "hellospa-admin" */ './page/admin'),
);
const MoarPage = React.lazy(
  () => import(/* webpackChunkName: "hellospa-moar" */ './page/moar'),
);
const HomePage = React.lazy(
  () => import(/* webpackChunkName: "hellospa-home" */ './page/home'),
);
const DropboxConnectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-dropboxconnect" */ './page/dropbox/connect'
    ),
);
const DropboxErrorPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-dropboxerrorpage" */ './components/deep-integration/error-page'
    ),
);
const DropboxSsoPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-dropboxssopage" */ './components/deep-integration/sso-page'
    ),
);
const DropboxConfirmStorageAccess = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-confirmstorageaccess" */ './components/deep-integration/storage-access/confirm'
    ),
);
const DropboxRequestStorageAccess = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-requeststorageaccess" */ './components/deep-integration/storage-access/request'
    ),
);
const SafetyFirst = React.lazy(
  () =>
    import(/* webpackChunkName: "hellospa-safetyfirst" */ './page/safetyfirst'),
);
const UserPostSignUp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-postsignup" */ './page/user-post-sign-up'
    ),
);
const RecoverPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-recoverPassword" */ './page/recover-password-page'
    ),
);
const NothingToSignPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-nothingToSign" */ 'hellospa/page/nothing-to-sign-page'
    ),
);
const SetPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-setPassword" */ './page/set-password-page'
    ),
);
const InformationFlagPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-emailactivation" */ './page/information-flag'
    ),
);
const HubspotTemplatePage = React.lazy(
  () =>
    import(/* webpackChunkName: "hellospa-hubspot" */ './components/hubspot'),
);
const HubspotRequestComplete = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-hubspotclose" */ './components/hubspot/hubspot-close'
    ),
);
const IntegrationStatusPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-integrationstatus" */ './components/integration/workflow-status'
    ),
);
const IntegrationCreateOneTimeDoc = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-integrationcreateonetimedoc" */ './components/integration/create-one-time-doc'
    ),
);
const IntegrationCreateTemplate = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-integrationcreatetemplate" */ './components/integration/create-template'
    ),
);
const IntegrationSendTemplatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-IntegrationSendTemplate" */ './components/integration/send-template'
    ),
);
const IntegrationEditTemplatePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-IntegrationEditTemplate" */ './components/integration/edit-template'
    ),
);
const IntegrationSendRemindersPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-IntegrationSendReminders" */ './components/integration/send-reminders'
    ),
);
const IntegrationCancelSignatureRequestPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-IntegrationCancelSignatureRequest" */ './components/integration/cancel-signature-request'
    ),
);
const PrepAndSendPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-prepandsend" */ './page/prep-and-send'
    ),
);
const DropboxLoginVerificationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-dropboxloginverification" */ './page/dropbox/login-verification'
    ),
);
const CancelSubscriptionPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-dropboxloginverification" */ './page/cancel-subscription'
    ),
);
const OfficeWordSelfSign = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-officewordselfsign" */ './components/microsoft/selfsign'
    ),
);
const OfficeWordSendSignature = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-officewordsendsignature" */ './components/microsoft/sendsignature'
    ),
);
const OfficeWordSettings = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-officewordsettings" */ './components/microsoft/settings'
    ),
);
const ChooseSignupPlanPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-choosesignupplanpage" */ './page/choose-signup-plan'
    ),
);
const ThrowAFrontendErrorPleasePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-throwAFrontendErrorPlease" */ './page/throw-a-frontend-error-please'
    ),
);
const PaymentPage = React.lazy(
  () => import(/* webpackChunkName: "hellospa-payment" */ './page/payment'),
);
const IdentVerificationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-identverification" */ './page/ident-verification'
    ),
);
const SharePointSetup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-sharepoint" */ './components/sharepoint'
    ),
);
const SharePointStatusPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-sharepointstatus" */ './components/sharepoint/status-page'
    ),
);
const SharePointSendForSignature = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-sharepoint-send-for-signature" */ './components/sharepoint/send-for-signature'
    ),
);
const SignatureRequestsLandingPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-signature-requests" */ './components/signature-requests/landing'
    ),
);
const HelpPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-help" */ './components/help/help-page'
    ),
);
const AccountSettings = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-account-settings" */ './page/account-settings'
    ),
);
const SignUpPageWrapper = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-signuppage" */ 'hellospa/page/authentication/sign-up-page-wrapper'
    ),
);
const SignInPageWrapper = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-progressivecheckoutsigninpage" */ 'hellospa/page/authentication/sign-in-page-wrapper'
    ),
);
const SignInMobileApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-signinmobileapp" */ 'hellospa/page/authentication/sign-in-mobile-app'
    ),
);
const JoinTeamPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-jointeampage" */ 'hellospa/page/authentication/join-team-page'
    ),
);
const NoAccessErrorPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-noaccesserrorpage" */ 'hellospa/page/no-access-error-page'
    ),
);
const ApiDashboardHome = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-apidashboard" */ './page/api-dashboard'
    ),
);
const AcceptTermsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-acceptterms" */ 'hellospa/page/authentication/accept-terms-page'
    ),
);
const TransferTemplatesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-templateconverter" */ 'hellospa/page/template-converter'
    ),
);
const SignFaxBridgePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "hellospa-signfaxbridge" */ 'hellospa/page/sign-fax-bridge'
    ),
);

if (Route.propTypes && Route.propTypes.component) {
  // There isn't a newer version of the 4.x branch with the correct proptypes. So
  // I'm just going to patch it so QA stops reporting this warning that looks
  // like an error.
  Route.propTypes.component = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      $$typeof: PropTypes.symbol,
    }),
  ]);
}

const NotFound = () => {
  // This will trigger our ErrorBoundary and report to Sentry The URL will be
  // part of the report.
  throw new Error('404 not found');
};

// const pathRegex = /\/([^/]+\.php)?/;

const RedirectHome = () => {
  return <RedirectTo url="/" />;
};

/**
 * <Routes holds all the routes for the `hellospa.js` bundle. It should contain
 * ONLY route configuration and should not depend on specific knowledge of the
 * current page.
 *
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231213701&cot=14
 */
function Routes() {
  const { isDeepIntegration } = useUnsafePageProps();
  const user = useHSUser();

  // These routes will be accessible by all visitors,
  // regardless of whether or not they're logged in.

  // <Switch doesn't like react fragments, so we have
  // to use an array with keys.
  //
  // These keys only need to be locally unique.
  const commonRoutes = [
    <Route
      key="FrontendError"
      exact
      path="/info/throwAFrontendErrorPlease"
      component={ThrowAFrontendErrorPleasePage}
    />,
    <LabeledRoute
      key="NoAccess"
      label="NoAccess"
      path="/info/noAccess"
      component={NoAccessErrorPage}
    />,
    <LabeledRoute
      key="JoinTeam"
      label="Join team"
      path="/account/confirmTeamInvite"
      component={JoinTeamPage}
    />,
  ];

  if (!user) {
    return (
      <Switch>
        {commonRoutes}
        <LabeledRoute
          label="Create an account"
          path="/account/signUp"
          component={SignUpPageWrapper}
        />
        <LabeledRoute
          label="Sign in"
          path="/account/logIn"
          component={SignInPageWrapper}
        />
        <LabeledRoute
          label="Sign in"
          path="/account/mobileLogIn"
          component={SignInMobileApp}
        />
        <LabeledRoute
          label="Accept terms"
          path="/account/acceptTerms"
          component={AcceptTermsPage}
        />
        <Route path="/dropbox/connect" component={DropboxConnectPage} />
        <Route
          path="/dropbox/logInVerification"
          component={DropboxLoginVerificationPage}
        />
        <RouteBoundary
          path="/dropbox/confirmStorageAccess"
          component={DropboxConfirmStorageAccess}
        />
        <RouteBoundary path="/dropbox/error" component={DropboxErrorPage} />
        <Route
          path="/dropbox/requestStorageAccess"
          component={DropboxRequestStorageAccess}
        />
        <Route path="/dropbox/sso" component={DropboxSsoPage} />
        <Route
          path="/integration/upgradeRequired"
          component={IntegrationStatusPage}
        />
        <Route
          path="/integration/enableThirdPartyCookies"
          component={IntegrationStatusPage}
        />
        <Route path="/integration/status" component={IntegrationStatusPage} />
        <Route
          path="/integration/createOneTimeDoc"
          component={IntegrationCreateOneTimeDoc}
        />
        <Route
          path="/integration/createTemplate"
          component={IntegrationCreateTemplate}
        />
        <Route
          path="/integration/sendTemplate"
          component={IntegrationSendTemplatePage}
        />
        <Route
          path="/integration/editTemplate"
          component={IntegrationEditTemplatePage}
        />
        <Route
          path="/integration/sendReminders"
          component={IntegrationSendRemindersPage}
        />
        <Route
          path="/integration/cancelSignatureRequest"
          component={IntegrationCancelSignatureRequestPage}
        />
        <Route path="/microsoft/selfSign" component={OfficeWordSelfSign} />
        <Route
          path="/microsoft/sendSignature"
          component={OfficeWordSendSignature}
        />
        <Route path="/microsoft/settings" component={OfficeWordSettings} />
        <Route path="/sharepoint/setup" component={SharePointSetup} />
        <Route path="/sharepoint/status" component={SharePointStatusPage} />
        <Route
          path="/sharepoint/sendForSignature"
          component={SharePointSendForSignature}
        />
        <LabeledRoute
          label="RecoverPassword"
          path="/account/recoverPassword"
          component={RecoverPassword}
        />
        <LabeledRoute
          label="NothingToSign"
          path="/info/nothingToSign"
          component={NothingToSignPage}
        />
        <LabeledRoute
          label="NothingToSign"
          path="/sign/:guid"
          component={NothingToSignPage}
        />
        <Route component={NotFound} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        {commonRoutes}
        <LabeledRoute
          label="ApiDashboard"
          path="/apidashboard"
          component={ApiDashboardHome}
        />
        <LabeledRoute
          label="AccountSettings"
          path="/accountSettings"
          component={AccountSettings}
        />
        <LabeledRoute
          label="SafetyFirst"
          path="/account/safetyfirst"
          component={SafetyFirst}
        />
        <LabeledRoute
          label="PostSignUpForm"
          path="/account/postSignUp"
          component={UserPostSignUp}
        />
        <LabeledRoute
          label="SetPassword"
          path="/onboarding/setPassword"
          component={SetPassword}
        />
        <LabeledRoute
          label="CancelSubscription"
          path="/account/cancelSubscription"
          component={CancelSubscriptionPage}
        />
        <LabeledRoute
          label="EmailActivation"
          path="/account/emailActivation"
          component={InformationFlagPage}
        />
        <LabeledRoute label="Admin" path="/admin" exact component={AdminPage} />
        <LabeledRoute label="Admin" path="/admin/*" component={AdminPage} />
        <LabeledRoute
          label="Editor"
          path="/editor/prepare"
          component={EditorPage}
        />
        <LabeledRoute
          label="Editor"
          path="/editor/editor"
          component={EditorPage}
        />
        <LabeledRoute label="Home" path="/home/index" component={HomePage} />
        <LabeledRoute
          label="ChooseSignMethod"
          path="/home/chooseSignMethod"
          component={HomePage}
        />
        <LabeledRoute label="Help" path="/home/help" component={HelpPage} />
        <LabeledRoute
          label="Documents"
          path="/home/manage"
          component={SignatureRequestsLandingPage}
        />
        <LabeledRoute
          label="HubspotTemplate"
          path="/hubspot/selectTemplate"
          component={HubspotTemplatePage}
        />
        <LabeledRoute
          label="HubspotComplete"
          path="/hubspot/close"
          component={HubspotRequestComplete}
        />
        <LabeledRoute
          label="ChoosePlan"
          path="/info/chooseSignupPlan"
          exact
          component={ChooseSignupPlanPage}
        />
        <LabeledRoute
          label="Payment"
          path="/info/payment"
          component={PaymentPage}
        />
        <LabeledRoute
          label="MoarFaxes"
          path="/internal/moar"
          exact
          component={MoarPage}
        />
        <LabeledRoute
          label="Templates"
          path="/home/createReusableDocs"
          exact
          component={TemplatesPage}
        />
        <LabeledRoute
          label="IdentSuccess"
          path="/info/identSuccess"
          component={IdentVerificationPage}
        />
        <LabeledRoute
          label="IdentIncomplete"
          path="/info/identIncomplete"
          component={IdentVerificationPage}
        />
        <LabeledRoute
          label="Templates"
          path="/home/transferTemplates"
          exact
          component={TransferTemplatesPage}
        />
        <LabeledRoute
          label="SignFaxBridge"
          path="/signFax/bridge"
          component={SignFaxBridgePage}
        />
        <LabeledRoute
          label={`prep-and-send${isDeepIntegration ? '.deep-integration' : ''}`}
          path="/prep-and-send/:transmissionGroupGuid"
          component={PrepAndSendPage}
        />

        {/*
              This route can't simply use renderHome, because it has
              page-specific parameters (3rd parameter of prepareSPA). So I have
              to use this awkward hack to make a full page redirect so the BE
              will provide the data needed to render the homepage.

              Accessing this URL from a mobile device with the app will launch
              the app. If a user arrives here without an app or on the desktop,
              it just redirects to the home page.
          */}
        <Route path="/account/mobileApp" component={RedirectHome} />
        <Route path="/" exact component={HomePage} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export const helloSpaRoutes = <Routes />;

/**
 * <Root is the top component in the tree, and the one that configures all the
 * common tools and contexts. It renders `<HelloSPA` and passes it the routes.
 *
 * # Storybook
 * At the moment our Storybook automatically wraps every story in a component
 * that acts like <HelloSPA. I'd like to get this inverted and give everyone a
 * batteries-included `<HelloSPAStory>` whose props should be:
 * `React.Component<Partial<HelloSPAProps>>`
 *
 * # Testing
 * Our tests also share the same wrapper that the Storybook decorator uses.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231372009&cot=14
 */
export default function Root(props) {
  setCsrfToken(props.csrfToken);
  /**
   * The original intention of layoutContext was to provide specifically the
   * data that our layout needs, that way each individual page didn't have to
   * manage that. They can juse use `<DefaultLayout>` and move along.
   *
   * I'm going to end up disassembling this context a piece at a time.
   * @deprecated
   */
  const layoutContext = React.useMemo(
    () => ({
      /**
       * Use the useHSUser() hook instead of layoutContext.
       *
       * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231624233&cot=14
       */
      user: props.user,
      /**
       * We're planning to move away from page props, but until that's complete
       * you can use `usePageProps()`
       *
       * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231624815&cot=14
       */
      pageProps: props.pageProps,
      /**
       * getCSRFToken() can be called from an AppAction/WebAppClient. There's no
       * need to pass it around components.
       */
      csrfToken: props.csrfToken,
      /**
       * useFeatureFlag('flag-name')
       */
      featureFlags: props.featureFlags,

      app: props.app,
      stripePublishableKey: props.stripePublishableKey,
      signature: props.signature,
      isDropbox: props.isDropbox,
      isEmbedded: props.isEmbedded,
      siteCode: props.siteCode,
      termsURL: props.termsURL,
      privacyURL: props.privacyURL,
      sessionInfo: props.pageProps.sessionInfo,
      preloadedTsmGroupKey: props.preloadedTsmGroupKey,
      isSpa: true,
    }),
    [props],
  );
  const appActions = buildWebAppClient(props.preloadedTsmGroupKey);
  const featureFlags = props.featureFlags || {};

  const store = constructStore(() => ({
    appActions,
    featureFlags,
    hasFeatureFlag: hasFeatureFlagFactory(featureFlags),
  }));

  const hsUser = props.user;

  useOnMount(() => {
    if ('Stripe' in window && props.stripePublishableKey) {
      Stripe.setPublishableKey(props.stripePublishableKey);
    }
  });

  let routes = helloSpaRoutes;
  if (hsUser) {
    routes = (
      <SignatureProviderImpl
        firstName={hsUser.settings?.firstName}
        lastName={hsUser.settings?.lastName}
        primarySignatureGuid={hsUser.primarySignatureGuid}
        signature={props.signature}
        csrfToken={getCSRFToken()}
        isEmbedded={props.isEmbedded}
        isDropbox={props.isDropbox}
      >
        {routes}
      </SignatureProviderImpl>
    );
  }

  return (
    <HelloSPA
      store={store}
      siteCode={props.siteCode}
      appActions={appActions}
      csrfToken={props.csrfToken}
      featureFlags={featureFlags}
      preloadedTsmGroupKey={props.preloadedTsmGroupKey}
      layoutContext={layoutContext}
      hsUser={hsUser}
      completedTours={Object.values(props.completedTours || {})}
      pageProps={{
        pageKey: props.pageKey,
        props: props.pageProps,
      }}
    >
      {routes}
    </HelloSPA>
  );
}

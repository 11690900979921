import signatureActions from 'signer-app/context/signer-app-client/signature';
import * as signer from 'signer-app/context/signer-app-client/signer';
import { logAppActionsInDevelopment } from 'signer-app/utils/log-app-actions';
import * as tours from 'signer-app/context/signer-app-client/tours';
import * as authentication from 'signer-app/context/signer-app-client/authentication';
import * as signerApi from 'signer-app/context/signer-app-client/signer-api';
import {
  AccountApi,
  AccountApiKeyApi,
  Middleware,
} from '@dropbox/sign-internal-client';
import { getCSRFToken, needsCSRFToken } from '../common/hs-fetch';
import { SignerApiMock } from 'signer-app/context/signer-app-client/signer-api-mock';

/**
 * SignAppClient is a subset of NamespacedAppActions.
 * Code in src/js/sign-components should useSignAppClient()
 */
export type SignAppClient = {
  signatures: ReturnType<typeof signatureActions>;
  signer: typeof signer;
  tours: typeof tours;
  signerApi: typeof signerApi;
  accountApi: AccountApi;
  accountApiKeyApi: AccountApiKeyApi;
  authentication: typeof authentication;
};

// There is a mock version of this used for tests.
/** istanbul ignore next */
export const makeSignAppClient = (
  preloadedTsmGroupKey: string,
): SignAppClient => {
  const hostname = new URL('/', window.location.href).host;
  const middleware: Middleware['pre'] = async (req) => {
    req.url = req.url.replace(
      'https://app.hellosign.com',
      `https://${hostname}`,
    );

    (req.init.headers as any).Accept = 'application/json';
    if (needsCSRFToken(req.url)) {
      // Adding global X-CSRF-Token
      (req.init.headers as any)['X-CSRF-Token'] = getCSRFToken();
    }

    // Add pre middleware
    return req;
  };

  const accountApi = new AccountApi().withPreMiddleware(middleware);
  const accountApiKeyApi = new AccountApiKeyApi().withPreMiddleware(middleware);
  const signerApi = new SignerApiMock('default');

  const actions = {
    signatures: signatureActions(preloadedTsmGroupKey),
    signer,
    tours,
    signerApi,
    accountApi,
    accountApiKeyApi,
    authentication,
  };
  return logAppActionsInDevelopment(actions);
};

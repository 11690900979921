/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type HelloRequest =
  | PrepAndSendRequest
  | SignerAppRequest
  | ModalResponse
  | EditSignersObject
  | HomePage;
/**
 * Is third-party (or Dropbox) integration enabled for this request
 *
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "Integration".
 */
export type Integration =
  | DeepIntegration
  | SharePointIntegration
  | HubSpotIntegration
  | HubSpotV2Integration;
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "UploadIntegrationOptions".
 */
export type UploadIntegrationOptions =
  | DropboxIntegration
  | OneDriveIntegration
  | GoogleDriveIntegration
  | BoxIntegration
  | EvernoteIntegration;
export type Field =
  | TextField
  | SignatureField
  | InitialsField
  | DateField
  | CheckboxField
  | RadioField
  | DropdownField
  | HyperlinkField;
export type TextField = {
  type: 'text';
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  autoFillType?: AutoFillType;
  linkId?: string;
  isDisabled?: boolean;
  fontSize: FontSize;
  originalFontSize?: FontSize;
  fontFamily: FontFamily;
  value?: string;
  placeholder?: string;
  masked?: boolean;
  isEditableMergeField?: boolean;
  lines?: string;
  numLines?: number;
  numCharsPerLine?: number;
  lineHeight?: number;
  integrationMetadata?: FieldIntegrationMetadata;
  validationType?: ValidationType;
  validationCustomRegex?: string;
  validationCustomRegexFormatLabel?: string;
  formLabel?: string;
  linkIdViaApi?: boolean | null;
};
export type FontSize = number;
export type SignatureField = {
  type: 'signature';
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  linkId?: string;
  isDisabled?: boolean;
  signature?: Signature;
  color?: Color;
  formLabel?: string;
};
export type InitialsField = {
  type: 'initials';
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  linkId?: string;
  isDisabled?: boolean;
  signature?: Signature;
  color?: Color;
  formLabel?: string;
};
export type DateField = {
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  linkId?: string;
  isDisabled?: boolean;
  fontSize: FontSize;
  originalFontSize?: FontSize;
  fontFamily: FontFamily;
  lineHeight?: number;
  value: string | null;
  placeholder?: string;
  type: 'date';
  dateFormat?: JSDateFormat;
  formLabel?: string;
};
export type CheckboxField = {
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  isDisabled?: boolean;
  type: 'checkbox';
  checked?: boolean;
  requirement?: string;
  groupLabel?: string;
  groupFormLabel?: string;
  group?: string;
  integrationMetadata?: FieldIntegrationMetadata;
  formLabel?: string;
};
export type RadioField = {
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  linkId?: string;
  isDisabled?: boolean;
  type: 'radiobutton';
  checked: boolean;
  requirement: string;
  groupLabel?: string;
  groupFormLabel?: string;
  group: string;
  formLabel?: string;
};
export type DropdownField = {
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  isDisabled?: boolean;
  type: 'dropdown';
  value?: string | null;
  options: string[];
  fontSize: FontSize;
  fontFamily: FontFamily;
  lineHeight?: number;
  formLabel?: string;
};
export type HyperlinkField = {
  type: 'hyperlink';
  pageIndex: number;
  id: string;
  apiId?: string;
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signer: string;
  required: boolean;
  readOnly: boolean;
  name: string;
  linkId?: string;
  isDisabled?: boolean;
  lineHeight?: number;
  fontFamily: FontFamily;
  fontSize: FontSize;
  value: string | null;
  url: string;
  formLabel?: string;
};
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "Action".
 */
export type Action = FieldVisibilityAction | GroupVisibilityAction;
export type Recipient = Role | Signer | FaxRecipient;
/**
 * This interface was referenced by `EditorData`'s JSON-Schema
 * via the `definition` "SignatureTypes".
 */
export type SignatureTypes = 'draw' | 'type' | 'upload' | 'phone';
export type ModalResponse =
  | NoModal
  | GoogleDriveModalIntegrationModal
  | DropboxIntegrationModal
  | DocToTemplateModal
  | CreateTemplateModal
  | FirstSignatureRequestModal;

export interface PrepAndSendRequest {
  guid: string;
  settings: Settings;
  options: Options;
  flags: Flags;
  accountSettings: AccountSettings;
  requestData: RequestData;
  embeddedData?: EmbeddedData;
  user: User;
}
export interface Settings {
  addCoverPage?: boolean;
  recipientOrder?: boolean;
  qes?: boolean;
  nom151?: boolean;
  recipientReassignment?: boolean;
  requireSignerInfo?: boolean;
  sendHighResFax?: boolean;
  needsCharge?: boolean;
  hasForm?: boolean;
}
export interface Options {
  /**
   * Embedded - force showing signer roles
   */
  forceSignerRoles?: boolean;
  /**
   * Embedded - force showing subject message
   */
  forceSubjectMessage?: boolean;
  /**
   * Embedded - force showing subject message
   */
  hideContinueButton?: boolean;
  isMeOnly?: boolean;
  /**
   * Hide Stepper icons
   */
  hideProgressStepper?: boolean;
  /**
   * Hide entire stepper
   */
  hideStepperContainer?: boolean;
  /**
   * Show modal asking for user first & last name
   */
  requireSignerInfo?: boolean;
  /**
   * Block use of CCs
   */
  blockCC?: boolean;
  /**
   * Lock the title
   */
  lockDocumentTitle?: boolean;
  /**
   * Lock the message
   */
  lockDocumentMessage?: boolean;
  dateFormat?: JSDateFormat;
  /**
   * A list of features that are being made available on this request
   */
  features: string[];
  integrationMetadata?: SignatureRequestIntegrationMetadata;
}
/**
 * Additional metadata used by the integration framework. This data is stored in the SuperGroupMetadata table
 *
 * This interface was referenced by `Options`'s JSON-Schema
 * via the `definition` "SignatureRequestIntegrationMetadata".
 */
export interface SignatureRequestIntegrationMetadata {
  associatedObjectId?: string;
  associatedObjectType?: string;
  context?: string;
  showMissingFieldsTooltip?: boolean;
}
export interface Flags {
  requestType: RequestType;
  /**
   * Is this HelloFax (vs HelloSign)?
   */
  isHelloFax?: boolean;
  /**
   * True if this is coming from a resend or draft flow
   */
  isResend?: boolean;
  /**
   * True if this request is coming from the Mobile app
   */
  isMobileAppRequest?: boolean;
  integration?: Integration;
  /**
   * True if the expiration field can be shown
   */
  allowExpiresAt?: boolean;
  /**
   * True if the underlying template for edit-and-resend has been modified
   */
  hasTemplateReuseWarning?: boolean;
  /**
   * True if subscription is eligible for creating template
   */
  displayDocToTemplateBanner?: boolean;
  /**
   * True if Doc to template banner checkbox is checked
   */
  shouldConvertDocToTemplate?: boolean;
  /**
   * Document to Template conversion ID
   */
  templateConversionCandidateId?: string;
  canUseDataValidation?: boolean;
  canUseCustomRegexDataValidation?: boolean;
  /**
   * Does this subscription have access to create forms
   */
  canUseForm?: boolean;
  /**
   * Indicates whether the request originated on Dropbox Fax
   */
  isFromFax?: boolean;
  disableExternalSignature?: boolean;
  /**
   * Indicates whether the user has an api plan or access to api
   */
  isApiUser?: boolean;
}
/**
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "DeepIntegration".
 */
export interface DeepIntegration {
  name?: 'deepintegration' | 'officeAddInSelfSign';
  data?: IntegrationData;
}
/**
 * Data relevant to integrations
 *
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "IntegrationData".
 */
export interface IntegrationData {
  externalCacheKey?: string;
  externalAppKey: string;
  externalAppPickerSrc: string;
  externalAccountId?: string;
  externalFolderPath?: string;
  externalFileName?: string;
  externalDefaultSavePath?: string;
  recipientsProvidedByHostPage?: boolean;
}
/**
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "SharePointIntegration".
 */
export interface SharePointIntegration {
  name?: 'SharePoint';
  data?: IntegrationData;
}
/**
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "HubSpotIntegration".
 */
export interface HubSpotIntegration {
  name?: 'hubspot';
  data?: IntegrationData;
}
/**
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "HubSpotV2Integration".
 */
export interface HubSpotV2Integration {
  name?: 'hubspotv2';
  data?: IntegrationData;
}
export interface AccountSettings {
  /**
   * Allow user to select a file from third-party website on 'Select documents' page
   */
  allowIntegrationsUpload?: boolean;
  /**
   * Ability to add signer pin per signer as part of the signature request
   */
  allowRecipientAccessCodes?: boolean;
  /**
   * Ability to add signer SMS authentication per signer as part of the signature request
   */
  allowRecipientSmsAuthNumbers?: boolean;
  /**
   * Ability to require signers to attach (both required or optional) attachments as part of their signing process
   */
  allowRecipientAttachments?: boolean;
  /**
   * Allow recipient reassignment checkbox on 'Add signers' page
   */
  allowRecipientReassignment?: boolean;
  /**
   * Should this user be given the ability to send Qualified E-Signatures
   */
  allowQualifiedSignature?: boolean;
  /**
   * Allow user to enable NOM 151 for the request
   */
  allowNom151?: boolean;
  /**
   * The SMS tools add-on has been purchased and this account has permission to use it
   */
  allowSmsSignatureDelivery?: boolean;
  /**
   * Allow template deletion from P&S workflow
   */
  allowTemplateDelete: boolean;
  /**
   * True if the current account has permission to create/edit templates
   */
  allowCreateEditTemplate?: boolean;
  /**
   * True if the current account has the ability to preview templates
   */
  allowTemplatePreview?: boolean;
  /**
   * Allow template signature requests to have optional signers if at least one signer is populated.
   */
  allowOptionalTemplateSigners?: boolean;
  /**
   * Allow SMS authentication
   */
  allowSmsAuth?: boolean;
  /**
   * The amount of sms authentication requests the account has left
   */
  smsAuthLeft: number;
  /**
   * Allow bulk send
   */
  allowBulkSend?: boolean;
  /**
   * Has a non-Enterprise+ plan that can use recipient access codes regardless of setting
   */
  canAccessRecipientAccessCodes?: boolean;
  /**
   * User's add-on tier for SMS Signature Delivery can be auto-upgraded
   */
  canUpgradeTierSmsSignatureDelivery?: boolean;
  /**
   * Is the account prevented from sending faxes to multiple recipients
   */
  noMultiFaxes?: boolean;
  /**
   * User subscription template quota
   */
  maxTemplateNumber?: number;
  /**
   * Is user at their account-type's limit for creating templates?
   */
  isAtTemplateLimit?: boolean;
  /**
   * Is user at their account-type's limit for sending signature request?
   */
  isAtDocumentLimit?: boolean;
  /**
   * Does the user's subsription have the HIPAA tag?
   */
  hasHIPAA?: boolean;
  /**
   * Is the plan in context a Blueberry plan?
   */
  isNewHsPlan?: boolean;
  /**
   * Is the plan in context a transactional plan?
   */
  isTransactionalPlan?: boolean;
  /**
   * User wants a copy of PDF/Fax sent to themselves
   */
  alwaysSendMeACopy?: boolean;
  /**
   * User wants a copy of complete/faxed PDF sent to recipients and CCs
   */
  alwaysSendOthersACopy?: boolean;
  /**
   * True if the fax request needs a one-time charge
   */
  needsCharge?: boolean;
  /**
   * Which charge flow the user needs to see
   */
  chargeType?: string;
  /**
   * The total price of the charge in cents
   */
  totalPriceInCents?: number;
  /**
   * The amount of free faxes the account has left
   */
  freeFaxPagesLeft?: number;
  /**
   * The amount of fax pages in the current request
   */
  faxPageCurrentCount?: number;
  /**
   * The maximum fax price users can send in a free account
   */
  overageFaxMaxLimitInCents?: number;
  /**
   * The normal price for a fax on the account's subscription
   */
  pricePerFaxInCents?: number;
  /**
   * How many pages until you are over the initial pricePerFax
   */
  initialPageLimit?: number;
  /**
   * How much each page costs after your initial limit
   */
  overagePriceInCentsPerPage?: number;
  /**
   * How much the price of a page multiplies based on the receiving country
   */
  internationalMultiplier?: number;
  uploadIntegrations: UploadIntegrations;
  /**
   * The SMS tools add-on has been purchased AND its setting is turned on via Admin Console
   */
  isSMSToolsOn?: boolean;
  /**
   * Should this user be given the ability to use eID secure sign
   */
  isEIdEnabled?: boolean;
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "UploadIntegrations".
 */
export interface UploadIntegrations {
  D?: DropboxIntegration;
  T?: OneDriveIntegration;
  G?: GoogleDriveIntegration;
  B?: BoxIntegration;
  E?: EvernoteIntegration;
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "DropboxIntegration".
 */
export interface DropboxIntegration {
  id: 'D';
  key: string;
  nonce: string;
  enabled: boolean;
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "OneDriveIntegration".
 */
export interface OneDriveIntegration {
  id: 'T';
  token?: string;
  clientId: string;
  nonce: string;
  enabled: boolean;
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "GoogleDriveIntegration".
 */
export interface GoogleDriveIntegration {
  id: 'G';
  key: string;
  token?: string;
  abbrKey: string;
  enabled: boolean;
  loginHint?: string;
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "BoxIntegration".
 */
export interface BoxIntegration {
  id: 'B';
  enabled: boolean;
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "EvernoteIntegration".
 */
export interface EvernoteIntegration {
  id: 'E';
  enabled: boolean;
}
export interface RequestData {
  files: File[];
  editorData: EditorData;
  recipients: Recipient[];
  ccs: (CCEmail | CCRole)[];
  title: string;
  message: string;
  expiresAt?: string;
  templates: Template[];
  coverPage?: CoverPage;
  bulkSend: BulkSend;
}
export interface File {
  status?: FileStatus;
  name: string;
  type: FileType;
  guid: string;
  documentGuid?: string;
  draftSnapshotGuid?: string;
  tsmGroupGuid?: string;
  templateGuid?: string;
  rootSnapshotGuid: string;
  order?: number;
  fields: Field[];
  pwRequired: boolean;
  pageCount?: number;
  externalFileCacheKey?: string;
  externalServiceReference?: string;
  externalServiceType?: string;
}
/**
 * Currently only being used for HubSpot V2 integration
 */
export interface FieldIntegrationMetadata {
  externalName?: string;
  externalType?: string;
  isTopField?: boolean;
}
export interface Signature {
  type: SignatureType;
  createType?: SignatureCreateType;
  createdAt?: string;
  lastUsedAt?: string;
  guid: string;
  width?: number;
  height?: number;
}
export interface EditorData {
  pages: Page[];
  fields: Field[];
  mergeFields: (MergeField | Field)[];
  pdfFields: Field[];
  editorFeatures?: string[];
  rules: Rule[];
  transform?: Transform;
  documents: Document[];
  signers: Recipient[];
  signerOptions: SignerOptions;
  editorOptions: EditorOptions;
  ccs?: (CCRole | CCEmail)[];
}
export interface Page {
  src: string;
  width: number;
  height: number;
  documentId?: string;
  orientation: number;
  snapshotGuid?: string;
  cdnSrc?: string | null;
}
export interface MergeField {
  name?: string;
  type?: 'text' | 'checkbox';
  integrationMetadata?: FieldIntegrationMetadata;
}
export interface Rule {
  id: string;
  triggerOperator: TriggerOperator;
  triggers: (
    | StringArrayTrigger
    | StringTrigger
    | NonNumericTrigger
    | NumericTrigger
  )[];
  actions: Action[];
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "StringArrayTrigger".
 */
export interface StringArrayTrigger {
  id: string;
  operator: StringArrayOperator;
  value: string[];
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "StringTrigger".
 */
export interface StringTrigger {
  id: string;
  operator: StringOperator;
  value: string;
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "NonNumericTrigger".
 */
export interface NonNumericTrigger {
  id: string;
  operator: NonNumericOperator;
  value: string | boolean;
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "NumericTrigger".
 */
export interface NumericTrigger {
  id: string;
  operator: NumericOperator;
  value: number;
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "FieldVisibilityAction".
 */
export interface FieldVisibilityAction {
  type: 'change-field-visibility';
  fieldId: string;
  hidden: boolean;
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "GroupVisibilityAction".
 */
export interface GroupVisibilityAction {
  type: 'change-group-visibility';
  groupId: string;
  hidden: boolean;
}
export interface Transform {
  rotate: number;
}
/**
 * Holds information about documents uploaded as part of the request. This is separate from files uploaded on the Prep and Send documents page because the ability to upload documents is available in the Editor as well.
 *
 * This interface was referenced by `EditorData`'s JSON-Schema
 * via the `definition` "Document".
 */
export interface Document {
  id: string;
  name: string;
  editable: boolean;
  snapshotGuid: string;
}
export interface Role {
  type: 'role';
  id: string;
  name: string;
  attachments: Attachment[];
  order?: number;
}
export interface Attachment {
  id: string;
  name: string;
  instructions: string;
  required: boolean;
}
export interface Signer {
  type: 'signer';
  id: string;
  email: string | null;
  name: string;
  role: null | Role;
  attachments: Attachment[];
  accessCode?: string;
  smsAuthNumber?: string;
  authType?: string;
  smsDeliveryNumber?: string;
  deliveryType?: DeliveryType;
  order?: number;
}
export interface FaxRecipient {
  type: 'fax';
  id: string;
  fax: string;
}
/**
 * This interface was referenced by `EditorData`'s JSON-Schema
 * via the `definition` "SignerOptions".
 */
export interface SignerOptions {
  fieldOptions?: FieldOptions;
  signatureType?: SignatureType1;
}
/**
 * This interface was referenced by `EditorData`'s JSON-Schema
 * via the `definition` "FieldOptions".
 */
export interface FieldOptions {
  dateFormat?: JSDateFormat;
}
/**
 * This interface was referenced by `EditorData`'s JSON-Schema
 * via the `definition` "SignatureType".
 */
export interface SignatureType1 {
  default?: SignatureTypes;
  allowed?: SignatureTypes[];
}
/**
 * This interface was referenced by `EditorData`'s JSON-Schema
 * via the `definition` "EditorOptions".
 */
export interface EditorOptions {
  allowEditSigners: boolean;
  allowEditDocuments: boolean;
  canUseCustomRegexDataValidation: boolean;
  canUseDataValidation: boolean;
}
export interface CCRole {
  type: 'ccRole';
  id: string;
  name: string;
}
export interface CCEmail {
  type: 'ccEmail';
  id: string;
  email: string;
  role?: CCRole;
}
export interface Template {
  guid: string;
  requestType: number;
  recipientReassignment: boolean;
  starred?: boolean;
  recipientOrder: boolean;
  updated?: string;
  dateFormat: PHPDateFormat;
  jsDateFormat?: JSDateFormat;
  files?: File[];
  editorData?: EditorData;
  recipients: Role[];
  ccs?: (CCRole | CCEmail)[];
  title: string;
  message: string;
  locked?: boolean;
  shortGuid?: string;
  isOwner: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  isEmbedded?: boolean;
  isSalesforceCreated?: boolean;
  isCreatedByIntegration?: boolean;
  linkDetails?: LinkDetails;
  createdBy: CreatedBy;
}
/**
 * This interface was referenced by `Template`'s JSON-Schema
 * via the `definition` "LinkDetails".
 */
export interface LinkDetails {
  shareableLink?: string;
  inPersonLink?: string;
  receiveSignedCopies: boolean;
}
/**
 * This interface was referenced by `Template`'s JSON-Schema
 * via the `definition` "CreatedBy".
 */
export interface CreatedBy {
  name: string;
  email: string;
  date: string;
}
export interface CoverPage {
  to: string;
  from: string;
  message: string;
}
export interface BulkSend {
  exampleFields: string[];
  signerFile?: SignerFile | null;
}
export interface SignerFile {
  status: FileStatus;
  name: string;
  type: SignerFileType;
  progress?: number;
}
export interface EmbeddedData {
  docGuids: string[];
  isEmbeddedRequest?: boolean;
  isEmbeddedTemplate?: boolean;
  isGmail?: boolean;
  skipMeNow?: boolean;
  usingTemplate: boolean;
  isCreatedFromTemplate?: boolean;
  parentUrl?: string;
  redirectUrl?: string;
  editedTemplateGuid?: string | null;
  isSelfSign: boolean;
  locale: string;
  appName?: string;
  apiApp: ApiApp;
}
export interface ApiApp {
  clientId: string;
  parentUrl?: string;
}
export interface User {
  id?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isPaidAccount: boolean;
  isConfirmedAccount: boolean;
  title?: string;
  company?: string;
  apiIdsEnabled: boolean;
  accountDateFormat: AccountDateFormat;
  dbxUserId?: string | null;
}
export interface SignerAppRequest {
  pages: Page[];
  fields: SignerField[];
  rules: Rule[];
  signedData?: SignedData;
}
export interface SignerField {
  type?: FieldType;
  pageIndex?: number;
  id?: string;
  apiId?: string;
  documentId?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  required?: boolean;
  readOnly?: boolean;
  name?: string;
  isAutoFill?: boolean;
  autoFillValue?: string;
  autoFillType?: AutoFillType;
  linkId?: string;
  isDisabled?: boolean;
  fontSize?: FontSize;
  originalFontSize?: FontSize;
  fontFamily?: FontFamily;
  value?: string | null;
  placeholder?: string;
  masked?: boolean;
  isEditableMergeField?: boolean;
  lines?: string;
  numLines?: number;
  numCharsPerLine?: number;
  lineHeight?: number;
  validationType?: ValidationType;
  validationCustomRegex?: string;
  validationCustomRegexFormatLabel?: string;
  signature?: Signature;
  color?: Color;
  dateFormat?: JSDateFormat;
  checked?: boolean;
  requirement?: string;
  groupLabel?: string;
  groupFormLabel?: string;
  group?: string;
  options?: string[];
  url?: string;
  formLabel?: string;
}
export interface SignedData {
  /**
   * This interface was referenced by `SignedData`'s JSON-Schema definition
   * via the `patternProperty` ".*".
   */
  [k: string]:
    | {
        type: 'inputsignature' | 'inputinitials';
        data: {
          guid: string;
        };
      }
    | {
        type: 'inputcheckmark' | 'inputradiobutton';
        data: {
          checked: boolean;
        };
      }
    | {
        type: 'inputtextbox' | 'inputdropdown';
        data: {
          value: string;
        };
      };
}
export interface NoModal {
  type: 'None';
}
export interface GoogleDriveModalIntegrationModal {
  type: 'GoogleDrive';
}
export interface DropboxIntegrationModal {
  type: 'Dropbox';
  dropboxIntegration: DropboxIntegration;
}
export interface DocToTemplateModal {
  type: 'DocToTemplate';
  templateConversionCandidateId: string;
  simplifyDocToTemplateModal: boolean;
}
export interface CreateTemplateModal {
  type: 'CreateTemplate';
  isPaid: boolean;
}
export interface FirstSignatureRequestModal {
  type: 'FirstSignatureRequestModal';
}
export interface EditSignersObject {
  signers: Signer[];
  signerSettings: SignerSettings;
  signerFeatures: SignerFeatures;
}
/**
 * SignerSettings
 *
 * This interface was referenced by `EditSignersObject`'s JSON-Schema
 * via the `definition` "SignerSettings".
 */
export interface SignerSettings {
  isSignerReassignment: boolean;
  isSignerOrder: boolean;
  isQualifiedSignature: boolean;
}
/**
 * signerFeatures
 *
 * This interface was referenced by `EditSignersObject`'s JSON-Schema
 * via the `definition` "SignerFeatures".
 */
export interface SignerFeatures {
  allowReassignment: boolean;
  allowRecipientOrder: boolean;
  allowQualifiedESignature: boolean;
}
export interface HomePage {
  delinquencyReminderModal: DelinquencyReminderModal;
}
/**
 * Modal displayed in Home page
 *
 * This interface was referenced by `HomePage`'s JSON-Schema
 * via the `definition` "DelinquencyReminderModal".
 */
export interface DelinquencyReminderModal {
  isDelinquencyReminderModalEligible: boolean;
  isTeamAdmin: boolean;
  planName: string;
  endDate: number | null;
  requiredV2Props: {
    billingPeriod?: string;
    countryCode?: string;
    daysUntilRenewal?: number;
    existingCurrency?: string;
    firstName?: string;
    funnelStep?: 'trial' | 'renewal';
    last4?: string;
    rateWithCurrency?: string;
  };
  modalType: number;
}

export enum JSDateFormat {
  JSDefault = 'default',
  MMDDYYYYSeparatedBySlash = 'MM / DD / YYYY',
  MMDDYYYYSeparatedByHyphen = 'MM - DD - YYYY',
  DDMMYYYYSeparatedBySlash = 'DD / MM / YYYY',
  DDMMYYYYSeparatedByHyphen = 'DD - MM - YYYY',
  YYYYMMDDSeparatedBySlash = 'YYYY / MM / DD',
  YYYYMMDDSeparatedByHyphen = 'YYYY - MM - DD',
}
/**
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "RequestType".
 */
export enum RequestType {
  SendDoc = 1,
  RequestSig = 2,
  ResponseSig = 3,
  Template = 4,
  SendTemplate = 5,
  Demo = 7,
  FinalizeDocGmail = 8,
  ReusableLink = 9,
  SelfSave = 10,
  EmbeddedRequest = 11,
  EmbeddedTemplate = 12,
  BulkSend = 13,
  EditAndResendDoc = 14,
}
/**
 * This interface was referenced by `Flags`'s JSON-Schema
 * via the `definition` "IntegrationNames".
 */
export enum IntegrationNames {
  DeepIntegration = 'deepintegration',
  OfficeSelfSign = 'officeAddInSelfSign',
  SharePoint = 'SharePoint',
  Hubspot = 'hubspot',
  HubspotV2 = 'hubspotv2',
}
/**
 * This interface was referenced by `AccountSettings`'s JSON-Schema
 * via the `definition` "UploadIntegrationType".
 */
export enum UploadIntegrationType {
  Box = 'B',
  Dropbox = 'D',
  Evernote = 'E',
  GoogleDrive = 'G',
  OneDrive = 'T',
}
/**
 * This interface was referenced by `File`'s JSON-Schema
 * via the `definition` "FileStatus".
 */
export enum FileStatus {
  Converting = 'converting',
  Ok = 'ok',
  FileDownloaded = 'file downloaded',
  Downloading = 'downloading',
  DownloadQueued = 'download queued',
  Deleted = 'deleted',
  Error = 'error',
  Error404 = '404 Error',
  PasswordRequired = 'password required',
  TooManyPages = 'too many pages',
  FileTooLarge = 'file too large',
  RetrieveError = 'retrieve error',
  BadRequest = 'bad request',
  BadAuth = 'bad auth',
}
/**
 * This interface was referenced by `File`'s JSON-Schema
 * via the `definition` "FileType".
 */
export enum FileType {
  Upload = 'Upload',
  Template = 'Template',
  External = 'External',
}
export enum AutoFillType {
  FullName = 'name',
  LastName = 'lastName',
  FirstName = 'firstName',
  Company = 'company',
  Email = 'email',
  EmailOrPhoneNumber = 'emailOrPhoneNumber',
  Title = 'title',
  None = 'none',
}
export enum FontFamily {
  Helvetica = 'helvetica',
  Arial = 'arial',
  Calibri = 'calibri',
  Cambria = 'cambria',
  Courier = 'courier',
  Georgia = 'georgia',
  Times = 'times',
  Trebuchet = 'trebuchet',
  Verdana = 'verdana',
  NotoSerif = 'notoSerif',
  NotoSans = 'notoSans',
  Roboto = 'roboto',
  RobotoMono = 'robotoMono',
}
/**
 * Validation options available for a text field
 */
export enum ValidationType {
  NumbersOnly = 'numbers_only',
  LettersOnly = 'letters_only',
  PhoneNumber = 'phone_number',
  BankRoutingNumber = 'bank_routing_number',
  BankAccountNumber = 'bank_account_number',
  EmailAddress = 'email_address',
  ZipCode = 'zip_code',
  SocialSecurityNumber = 'social_security_number',
  EmployerIdentificationNumber = 'employer_identification_number',
  CustomRegex = 'custom_regex',
}
export enum SignatureType {
  Signature = 'S',
  Initials = 'I',
}
export enum SignatureCreateType {
  API = 'A',
  Canvas = 'C',
  Upload = 'U',
  Typed = 'T',
  Email = 'E',
  Unknown = 'X',
}
export enum Color {
  Black = 'black',
  Blue = 'blue',
  Red = 'red',
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "TriggerOperator".
 */
export enum TriggerOperator {
  AND = 'AND',
  OR = 'OR',
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "StringArrayOperator".
 */
export enum StringArrayOperator {
  Any = 'any',
  None = 'none',
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "StringOperator".
 */
export enum StringOperator {
  Has = 'has',
  HasNot = 'hasNot',
  Match = 'match',
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "NonNumericOperator".
 */
export enum NonNumericOperator {
  Is = 'is',
  Not = 'not',
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "NumericOperator".
 */
export enum NumericOperator {
  Lt = 'gt',
  Gt = 'lt',
}
/**
 * This interface was referenced by `Rule`'s JSON-Schema
 * via the `definition` "ActionType".
 */
export enum ActionType {
  ChangeFieldVisibility = 'change-field-visibility',
  ChangeGroupVisibility = 'change-group-visibility',
}
export enum DeliveryType {
  Email = 'EMAIL',
  EmailAndSMS = 'EMAIL-SMS',
}
export enum PHPDateFormat {
  Default = 'Default',
  MMDDYYYYSeparatedBySlash = 'm / d / Y',
  MMDDYYYYSeparatedByHyphen = 'm - d - Y',
  DDMMYYYYSeparatedBySlash = 'd / m / Y',
  DDMMYYYYSeparatedByHyphen = 'd - m - Y',
  YYYYMMDDSeparatedBySlash = 'Y / m / d',
  YYYYMMDDSeparatedByHyphen = 'Y - m - d',
}
/**
 * This interface was referenced by `SignerFile`'s JSON-Schema
 * via the `definition` "SignerFileType".
 */
export enum SignerFileType {
  Signer = 'Signer',
}
/**
 * Preferred date format on account settings
 *
 * This interface was referenced by `User`'s JSON-Schema
 * via the `definition` "AccountDateFormat".
 */
export enum AccountDateFormat {
  MMDDYYYYSeparatedBySlash = 'MM / DD / YYYY',
  MMDDYYYYSeparatedByHyphen = 'MM - DD - YYYY',
  DDMMYYYYSeparatedBySlash = 'DD / MM / YYYY',
  DDMMYYYYSeparatedByHyphen = 'DD - MM - YYYY',
  YYYYMMDDSeparatedBySlash = 'YYYY / MM / DD',
  YYYYMMDDSeparatedByHyphen = 'YYYY - MM - DD',
}
export enum FieldType {
  Checkbox = 'checkbox',
  Date = 'date',
  Initials = 'initials',
  Signature = 'signature',
  Text = 'text',
  Dropdown = 'dropdown',
  Radio = 'radiobutton',
  Hyperlink = 'hyperlink',
}

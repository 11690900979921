/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Router } from 'react-router-dom';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import { Provider as ReduxProvider } from 'react-redux';
import { FeatureFlagProvider } from 'js/sign-components/common/feature-flag';
import { FeatureFlags } from 'js/sign-components/common/feature-flags';
import { setCsrfToken } from 'js/sign-components/common/hs-fetch';
import { ToursProvider } from 'js/sign-components/sign-app-client/tours-provider';
import { initAppElement } from 'common/components/hello-modal';
import type { AppActions } from 'hello-react/web-app-client';
import { AppContextProvider } from 'hello-react/web-app-client/app-context-provider';
import { Store } from 'redux';
import { createBrowserHistory, History } from 'history';
import { NotificationBannerProvider } from 'signer-app/parts/notifications/notification-banners';
import { debugTestIds } from './debug-test-ids';
import { TourName } from 'js/sign-components/common/TourName';
import EmailValidationProvider from 'js/sign-components/recipient/email-validation-context';
import {
  NetworkMode,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const pathRegex = /\/([^/]+\.php)?/;
const defaultFallback = (
  // This should either be empty, or be something production ready because the
  // Home is also lazy loaded.
  <div />
);
export type HelloReactApplicationProps = React.PropsWithChildren<{
  suspenseFallback?: React.ReactNode;
  featureFlags: FeatureFlags;
  csrfToken: string;
  completedTours?: TourName[];
  store: Store;
  appActions: AppActions;
  history?: History<unknown>;
}>;
/**
 * <HelloReactApplication handles the common tools/contexts for bundles in
 * `hellosign-web`.  This component was refactored/extracted from `<HelloSPA`.
 * As we add more bundles, each should have its own bundle component that wraps
 * <HelloReactApplication. And then around that, we create a <YourBundleStory
 * component that provides all the default values. That's the component to put
 * in stories, and then it gets picked up naturally when a Jest test renders
 * that story.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231213053&cot=14
 */
export default function HelloReactApplication({
  children,
  featureFlags,
  csrfToken,
  suspenseFallback = defaultFallback,
  completedTours = [],
  store,
  appActions,
  history: maybeHistory,
}: HelloReactApplicationProps) {
  setCsrfToken(csrfToken);
  initAppElement();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        // Although networkMode is a great idea in theory, headless selenium tests trick react-query
        // into thinking there is no network connection and all queries are 'paused'
        // https://tanstack.com/query/v4/docs/react/guides/network-mode
        defaultOptions: {
          queries: {
            cacheTime: Infinity,
            staleTime: Infinity,
            networkMode: 'always' as NetworkMode,
            retry: false, // don't retry on failure
            refetchOnWindowFocus: false, // don't refetch when a window/tab is refocused
            refetchOnReconnect: false, // don't refetch if the user regains internet connection
          },
        },
      }),
  );
  const history = React.useMemo(() => {
    if (IS_STORYBOOK || NODE_ENV === 'test') {
      if (maybeHistory) {
        return maybeHistory;
      }
    }

    // In development you can access a production-like environment through
    // `/webapp_prod.php`. This assumes if your path starts with a PHP file,
    // then it should be the base for routing.
    const [basename] = window.location.pathname.match(pathRegex) || [undefined];
    return createBrowserHistory({ basename });
  }, [maybeHistory]);

  return (
    <React.Suspense fallback={suspenseFallback}>
      <QueryClientProvider client={queryClient}>
        {debugTestIds}
        <Router history={history}>
          <AppContextProvider value={appActions}>
            <HSIntlProvider>
              <FeatureFlagProvider featureFlags={featureFlags || {}}>
                <ReduxProvider store={store}>
                  <ToursProvider completedTours={completedTours}>
                    <NotificationBannerProvider>
                      <EmailValidationProvider>
                        {children}
                      </EmailValidationProvider>
                    </NotificationBannerProvider>
                  </ToursProvider>
                </ReduxProvider>
              </FeatureFlagProvider>
            </HSIntlProvider>
          </AppContextProvider>
        </Router>
      </QueryClientProvider>
    </React.Suspense>
  );
}

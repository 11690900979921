import hsFetch from 'js/sign-components/common/hs-fetch';
import {
  SignAiSummary,
  SignAiTranslation,
  SignAiChatMessageResponse,
  SignAiChatStartResponse,
} from 'js/sign-components/generated/types/SignAi';
import { assertDataSchemaResponse } from 'signer-app/utils/response-helpers';
import {
  validateSignAiSummary,
  validateSignAiTranslation,
  validateSignAiChatStartResponse,
  validateSignAiChatMessageResponse,
} from 'js/sign-components/generated/validators/validateSignAi';

export type AiSummaryResponseType = {
  summary: string;
};
export type AiTsmGroupGuidFromPreloadedKeyResponseType = {
  tsmGroupGuid: string;
};

export type AiUserDataResponseType = {
  account: {
    guid: string;
  };
};
export type AiSummaryTranslationResponseType = {
  translation: string;
};

export type AiSummaryRequestArgsType = {
  chatId: string;
  preloadedTsmGroupKey: string;
};

// Using text type as String wrapper class rather than primitive string because of formik
export type AiTranslationRequestArgsType = {
  text: String;
  language: string;
};

export type ChatStartResponseType = {
  chatId: string;
};

export type AiChatMessageArgsType = {
  chatId: string;
  chatMessage: string;
};

export type ChatMessageResponseType = {
  text: string;
  fileUrl: string;
};

export type SignAiChatStartRequestArgsType = {
  preloadedTsmGroupKey?: string;
  tsmGuid?: string;
};

export async function chatStart({
  preloadedTsmGroupKey,
  tsmGuid,
}: SignAiChatStartRequestArgsType): Promise<SignAiChatStartResponse> {
  let url = '/signAi/chatStart';

  if (preloadedTsmGroupKey) {
    url += `?preloaded_tsm_group_key=${preloadedTsmGroupKey}`;
  } else if (tsmGuid) {
    url += `?tsm_guid=${tsmGuid}`;
  }

  const response = await hsFetch(url);
  return assertDataSchemaResponse(response, validateSignAiChatStartResponse);
}

export async function getDocumentAiSummary({
  chatId,
  preloadedTsmGroupKey,
}: AiSummaryRequestArgsType): Promise<SignAiSummary> {
  const response = await hsFetch(
    `/signAi/summarize?chat_id=${chatId}&preloaded_tsm_group_key=${preloadedTsmGroupKey}`,
  );
  return assertDataSchemaResponse(response, validateSignAiSummary);
}

export async function getTranslation({
  text,
  language,
}: AiTranslationRequestArgsType): Promise<SignAiTranslation> {
  const response = await hsFetch(
    `/signAi/translate?text=${text}&language=${language}`,
  );
  return assertDataSchemaResponse(response, validateSignAiTranslation);
}

export async function chatMessage({
  chatId,
  chatMessage,
}: AiChatMessageArgsType): Promise<SignAiChatMessageResponse> {
  const url = `/signAi/chatMessage?chat_id=${chatId}&chat_message=${chatMessage}`;
  const response = await hsFetch(url);
  return assertDataSchemaResponse(response, validateSignAiChatMessageResponse);
}

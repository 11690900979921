import React from 'react';
import { createSimpleDataContext } from 'js/sign-components/common/create-simple-data-context';
import type { SignAppClient } from './sign-app-client';

const { Provider, useContext: useSignAppClient } =
  createSimpleDataContext<SignAppClient>('SignAppClient');
export { useSignAppClient };
type ProviderProps = React.PropsWithChildren<{
  client: SignAppClient;
}>;

export function SignAppClientProvider({ client, children }: ProviderProps) {
  return <Provider value={client}>{children}</Provider>;
}
